import moment from 'moment';

const isOverlap = (
  holidayStartDate,
  holidayEndDate,
  sicknessStartDate,
  sicknessEndDate
) => {
  let hStartDate = moment(holidayStartDate);
  let hEndDate = moment(holidayEndDate);
  let sickStartDate = moment(sicknessStartDate);
  let sickEndDate = moment(sicknessEndDate);

  return hStartDate.isSameOrBefore(sickEndDate) && hEndDate.isSameOrAfter(sickStartDate);
};

export const holidayOverlapCalculator = (holidayRequests, sickLeaves) => {
  for (let i = 0; i < holidayRequests.length; i++) {
    for (let j = 0; j < sickLeaves.length; j++) {
      if (
        isOverlap(
          holidayRequests[i].startDate,
          holidayRequests[i].endDate,
          sickLeaves[j].sicknessStartDate,
          sickLeaves[j].sicknessEndDate
        )
      ) {
        return true;
      }
    }
  }
  return false;
};
