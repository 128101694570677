export const pendingRequestsCalculator = ( teamHolidayRequests, teamSickLeaves ) => {

  let pendingLeaveRequestCount = 0;
  let pendingSickLeaveCount = 0;

  if (teamHolidayRequests.length > 0) {
    teamHolidayRequests.forEach(item => {
      if (item.status === "pending") pendingLeaveRequestCount++;
    });
  }

  if (teamSickLeaves.length > 0) {
    teamSickLeaves.forEach(item => {
      if (item.status === "Pending") pendingSickLeaveCount++;
    });
  }

  return {
    pendingLeaveRequestCount: pendingLeaveRequestCount,
    pendingSickLeaveCount: pendingSickLeaveCount
  };
};
