import React, { Component } from "react";
import {dayCalculationUtilities} from "./logic";

class SickLeaveFormDatesLabel extends Component {

  getDatesLabel = () => {
    if (this.props.sicknessEndDate === undefined || this.props.sicknessEndDate === null)
      return `${dayCalculationUtilities.getFormattedDate(this.props.sicknessStartDate)} - (ongoing)`;
    else
      return `${dayCalculationUtilities.getFormattedDate(this.props.sicknessStartDate)} - ${dayCalculationUtilities.getFormattedDate(this.props.sicknessEndDate)}`;
  };

  render() {
    return (
      <span>
        {this.getDatesLabel()}
      </span>
    );
  }
}

export default SickLeaveFormDatesLabel;
