import React, { Component } from "react";

import moment from "moment";

import { database } from "../shared/database";
import {
  FieldContainer,
  FixedWidthLabel,
  RedSpan,
  SmallButton,
  PageHeading
} from "../shared/styledComponents";
import ToolTip from "../shared/toolTip";
import { dayCalculationUtilities } from "../shared/logic";
import DatePicker from "react-datepicker";
import "../../css/pwg-react-datepicker.css";
import { formValidationHelper } from "../shared/formValidationHelper";
import { datepickerFormat } from "../../constants";

class HolidayRequestForm extends Component {
  state = {
    allHolidayRequestDays: [],
    allHolidayDays: [],
    days: 0,
    daysOfLeaveRequested: null,
    endDate: undefined,
    fixedHolidayDays: [],
    holidayDayType: 1,
    inputHighlights: {
      isStartDateHighlighted: false,
      isEndDateHighlighted: false
    },
    isRequestedDaysExceedsRemainingLeave: false,
    isOnlyOneDayOrPartOfDayRequested: false,
    startDate: undefined,
    token: this.props.token,
    year: undefined,
    calendarDate: this.props.calendarDate,
    isRedirectFromCalendar: false,
    isAddedByAdmin: this.props.match.params.id !== undefined,
    createdForUserName: "",
    description: ""
  };

  componentDidMount = async () => {
    this.getHolidayDays();

    // Get user details if admin user
    if (this.state.isAddedByAdmin) {
      const userDetails = await database.call("helpers/getUser/", {
        userId: this.props.match.params.id,
        year: 2018,
        token: this.props.token
      });

      await this.setState({
        createdForUserName: `${userDetails.data.firstName} ${
          userDetails.data.lastName
        }`
      });
    }

    // If redirected from calendar, set date
    // @Cleanup - is there a better way of dealing with 'global' variables at the App level. Redux? Context?
    if (this.props.calendarDate) {
      this.updateStartDate(this.props.calendarDate);
      await this.setState({isRedirectFromCalendar: true});
      this.props.cancelAddLeaveRequest();
    }
  };

  componentDidUpdate = async prevProps => {
    if (this.props.currentYear !== prevProps.currentYear) {
      this.getHolidayDays();
      await this.setState({
        startDate: undefined,
        endDate: undefined
      });
    }
  };

  calculateDays = async () => {
    let updatedDays = 0;
    await this.setState({ daysOfLeaveRequested: "Calculating" });

    const isOnlyOneDayRequested = dayCalculationUtilities.isStartDateSameAsEndDate(
      this.state.startDate,
      this.state.endDate
    );

    let holidayDayType = this.state.holidayDayType;
    if (isOnlyOneDayRequested === true) {
      updatedDays = this.getDaysExcludingFixedHolidayDaysWhenOnlyOneDaySelected(
        this.state.startDate
      );
    } else {
      holidayDayType = 1;

      const holidaysDaysBetweenDates = await database.call(
        "myHolidays/holidayDaysBetweenDates",
        {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          token: this.props.token
        }
      );

      updatedDays = holidaysDaysBetweenDates.data;
    }

    await this.setState({
      inputHighlights: {
        isStartDateHighlighted: updatedDays <= 0,
        isEndDateHighlighted: updatedDays <= 0
      },
      isOnlyOneDayOrPartOfDayRequested:
        updatedDays === 1 || updatedDays === 0.5,
      holidayDayType: holidayDayType
    });

    // Update days label after short interval so label doesn't flicker
    setTimeout(() => {
      this.setState(
        {
          days: updatedDays
        },
        this.updateDaysRequestedLabel
      );
    }, 250);
  };

  getHolidayDays = async () => {
    const fixedHolidayDays = await database.call(
      "myHolidays/fixedHolidayDays",
      {
        year: this.props.currentYear,
        token: this.props.token
      }
    );

    let userId = this.state.isAddedByAdmin
      ? this.props.match.params.id
      : this.props.userId;

    const holidayRequests = await database.call(
      "myHolidays/holidayRequest/getAllByUser",
      {
        userId: userId,
        token: this.props.token,
        year: this.props.currentYear
      }
    );

    let fixedHolidayDaysMoment = dayCalculationUtilities.convertDateStringArrayIntoMomentArray(
      fixedHolidayDays.data
    );

    let allHolidayDays = dayCalculationUtilities.getArrayOfIndividualLeaveDays(
      holidayRequests.data
    );

    let allHolidayRequestDaysArray = dayCalculationUtilities.getArrayOfIndividualLeaveDays(
      holidayRequests.data
    );

    allHolidayDays.push(...fixedHolidayDaysMoment);

    await this.setState({
      allHolidayDays: allHolidayDays,
      fixedHolidayDays: fixedHolidayDaysMoment,
      allHolidayRequestDays: allHolidayRequestDaysArray
    });
  };

  onHandleSubmit = async event => {
    this.preventDefault(event);

    const validationResult = formValidationHelper.validateLeaveRequestForm(
      this.state,
      this.props.loadingMessages.alert,
      this.state.inputHighlights,
      this.props.holidayRequests,
      this.props.remainingHolidayDays,
      this.state.allHolidayRequestDays
    );

    if (validationResult.isValid && this.state.days > 0) {
      this.submitHolidayRequest(this.state);
    } else {
      await this.setState({
        inputHighlights: validationResult.inputHighlights
      });
    }
  };

  handleInputChange = event => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    });
  };

  submitHolidayRequest = async holidayRequest => {
    this.props.loadingMessages.addMessage("Saving leave request");

    let createdByUserId = this.state.isAddedByAdmin ? this.props.userId : null;
    let holidayRequestUserId = this.state.isAddedByAdmin
      ? this.props.match.params.id
      : this.props.userId;

    await database.call("myHolidays/holidayRequest/add", {
      startDate: dayCalculationUtilities.convertDateToStringForSubmission(
        holidayRequest.startDate
      ),
      endDate: dayCalculationUtilities.convertDateToStringForSubmission(
        holidayRequest.endDate
      ),
      description: holidayRequest.description,
      holidayDayType: holidayRequest.holidayDayType,
      userId: holidayRequestUserId,
      createdByUserId: createdByUserId,
      token: this.props.userId
    });

    console.log("End of holiday request", this.state.isRedirectFromCalendar);

    if (this.state.isRedirectFromCalendar === true)
      this.props.history.push("/");
    else if (this.state.isAddedByAdmin)
      this.props.history.push("/adminEditStaff/" + holidayRequestUserId);
    else
      this.props.history.push("/myHoliday");

    this.props.loadingMessages.removeMessage("Saving leave request");
  };

  predictEndDateIfNotAlreadySet = async () => {
    if (!this.state.userHasEditedEndDate) {
      await this.setState(
        {
          endDate: this.state.startDate,
          inputHighlights: formValidationHelper.removeHighlight(
            this.state.inputHighlights,
            "isEndDateHighlighted"
          )
        },
        this.calculateDays
      );
    } else this.calculateDays();
  };

  updateDaysRequestedLabel = async () => {
    let daysOfLeaveRequested = "Calculating...";
    let isRequestedDaysExceedsRemainingLeave = false;

    if (this.state.days !== -1) {
      if (
        this.state.startDate !== undefined &&
        this.state.endDate !== undefined &&
        this.state.days <= 0
      )
        daysOfLeaveRequested = "No days requested (all fixed holiday days)";
      else {
        if (this.state.days === 0) daysOfLeaveRequested = "No days requested";
        else
          daysOfLeaveRequested = `Total: ${this.state.days} ${
            this.state.days === 1 ? "day" : "days"
          }`;

        if (this.state.days > this.props.remainingHolidayDays) {
          isRequestedDaysExceedsRemainingLeave = true;
          if (this.props.remainingHolidayDays > 0)
            daysOfLeaveRequested += ` (You only  have ${
              this.props.remainingHolidayDays
            } days available)`;
          else daysOfLeaveRequested += " (You have no leave days available)";
        }
      }
    }

    await this.setState({
      daysOfLeaveRequested: daysOfLeaveRequested,
      isRequestedDaysExceedsRemainingLeave: isRequestedDaysExceedsRemainingLeave
    });
  };

  updateStartDate = async day => {
    const momentUtc = day.utc().add(1, "hour");
    if (
      formValidationHelper.validateStartDateBeforeOrEqualToEndDate(
        momentUtc,
        this.state.endDate,
        this.state.userHasEditedEndDate,
        this.props.loadingMessages.alert
      )
    ) {
      let newEndDate = this.state.endDate;
      if (!this.state.userHasEditedEndDate) newEndDate = momentUtc;

      await this.setState({
        startDate: momentUtc,
        endDate: newEndDate,
        inputHighlights: formValidationHelper.removeHighlight(
          this.state.inputHighlights,
          "isStartDateHighlighted"
        )
      });

      this.calculateDays();
    }
  };

  updateEndDate = async day => {
    const momentUtc = day.utc().add(1, "hour");

    if (
      formValidationHelper.validateStartDateBeforeOrEqualToEndDate(
        this.state.startDate,
        momentUtc,
        true,
        this.props.loadingMessages.alert
      )
    ) {
      await this.setState(
        {
          endDate: momentUtc,
          inputHighlights: formValidationHelper.removeHighlight(
            this.state.inputHighlights,
            "isEndDateHighlighted"
          ),
          userHasEditedEndDate: true
        },
        this.calculateDays
      );
    }
  };

  onUpdateHolidayDayType = async event => {
    await this.setState(
      {
        holidayDayType: Number(event.currentTarget.value)
      },
      this.calculateDays
    );
  };

  // @Cleanup - function may not be needed any more
  getDaysExcludingFixedHolidayDaysWhenOnlyOneDaySelected = day => {
    let updatedDays = 0;
    if (day !== undefined) {
      for (let i = 0; i < this.state.fixedHolidayDays.length; i++) {
        if (day.isSame(this.state.fixedHolidayDays[i], "day")) return 0;
      }
      updatedDays = this.state.holidayDayType === 1 ? 1 : 0.5;
    }
    return updatedDays;
  };

  isDateAvailable = day => {
    return (
      dayCalculationUtilities.isWeekday(day) &&
      dayCalculationUtilities.isDayInRangeOfDays(day, this.state.allHolidayDays)
    );
  };

  preventDefault = e => {
    e.preventDefault();
  };

  onCancelButton = () => {
    if (this.state.isRedirectFromCalendar === true)
      this.props.history.push("/");
    else
      this.props.history.push("/myHoliday");
  };

  componentWillUnmount = () => {
    this.props.cancelAddLeaveRequest();
  };

  render() {
    return (
      <div
        style={{
          marginRight: "25px",
          marginTop: "10px",
          marginBottom: "10px",
          paddingLeft: "25px",
          width: "100%"
        }}
      >
        {!this.props.isShowHolidayRequestForm && (
          <form onSubmit={this.onHandleSubmit}>
            <div>
              <PageHeading>
                New Leave Request{" "}
                {this.state.isAddedByAdmin && (
                  <span>for {this.state.createdForUserName}</span>
                )}
              </PageHeading>
              <hr />
              {this.state.isAddedByAdmin && (
                <p>
                  As an administrator, you are adding a holiday request for
                  someone else.
                </p>
              )}
              <FieldContainer>
                <FixedWidthLabel
                  htmlFor="startDate"
                  style={formValidationHelper.getLabelStyle(
                    this.state.inputHighlights.isStartDateHighlighted
                  )}
                >
                  <RedSpan>*</RedSpan>
                  First full day of leave:
                </FixedWidthLabel>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.updateStartDate}
                  minDate={moment(`${this.props.currentYear}-01-01`)}
                  maxDate={moment(`${this.props.currentYear}-12-31`)}
                  filterDate={this.isDateAvailable}
                  dateFormat={datepickerFormat}
                  placeholderText="Click to select a date"
                  name="startDate"
                  id="startDate"
                  autoComplete="off"
                  className={formValidationHelper.getDatepickerHighlightClassName(
                    this.state.inputHighlights.isStartDateHighlighted
                  )}
                  onChangeRaw={this.preventDefault}
                />
              </FieldContainer>
              <FieldContainer>
                <FixedWidthLabel
                  htmlFor="endDate"
                  style={formValidationHelper.getLabelStyle(
                    this.state.inputHighlights.isEndDateHighlighted
                  )}
                >
                  <RedSpan>*</RedSpan>
                  Last full day of leave:
                </FixedWidthLabel>
                <DatePicker
                  selected={this.state.endDate}
                  minDate={moment(`${this.props.currentYear}-01-01`)}
                  maxDate={moment(`${this.props.currentYear}-12-31`)}
                  onChange={this.updateEndDate}
                  filterDate={this.isDateAvailable}
                  dateFormat={datepickerFormat}
                  placeholderText="Click to select a date"
                  name="endDate"
                  id="endDate"
                  autoComplete="off"
                  className={formValidationHelper.getDatepickerHighlightClassName(
                    this.state.inputHighlights.isEndDateHighlighted
                  )}
                  onChangeRaw={this.preventDefault}
                />
              </FieldContainer>
              <FieldContainer>
                <FixedWidthLabel htmlFor="description">Notes:</FixedWidthLabel>
                <textarea
                  rows={3}
                  cols={60}
                  name={"description"}
                  value={this.state.description}
                  onChange={this.handleInputChange}
                />
                <ToolTip>
                  You can use this to give more information to your manager
                  about the leave request
                </ToolTip>
              </FieldContainer>
            </div>
            <div style={{ height: "40px", paddingLeft: "265px" }}>
              {this.state.isOnlyOneDayOrPartOfDayRequested && (
                <div>
                  <input
                    type="radio"
                    id="allDayRadioButton"
                    name="holidayDayType"
                    value={1}
                    checked={Number(this.state.holidayDayType) === 1}
                    onChange={this.onUpdateHolidayDayType}
                  />
                  <label htmlFor="allDayRadioButton">All Day</label>
                  <input
                    style={{ marginLeft: "25px" }}
                    type="radio"
                    id="amOnlyRadioButton"
                    name="holidayDayType"
                    value={2}
                    checked={Number(this.state.holidayDayType) === 2}
                    onChange={this.onUpdateHolidayDayType}
                  />
                  <label htmlFor="amOnlyRadioButton">AM Only</label>
                  <input
                    style={{ marginLeft: "25px" }}
                    type="radio"
                    id="pmOnlyRadioButton"
                    name="holidayDayType"
                    value={3}
                    checked={Number(this.state.holidayDayType) === 3}
                    onChange={this.onUpdateHolidayDayType}
                  />
                  <label htmlFor="pmOnlyRadioButton">PM Only</label>
                </div>
              )}
            </div>
            <div
              style={{
                textAlign: "right",
                height: "35px",
                paddingRight: "145px"
              }}
            >
              {this.state.isRequestedDaysExceedsRemainingLeave ? (
                <RedSpan data-testid="daysOfLeaveRequested">
                  {this.state.daysOfLeaveRequested}
                </RedSpan>
              ) : (
                <span data-testid="daysOfLeave">
                  {this.state.daysOfLeaveRequested}
                </span>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "144px",
                paddingLeft: "80px",
                alignItems: "center"
              }}
            >
              <SmallButton type="button" onClick={this.onCancelButton}>
                Cancel
              </SmallButton>
              <SmallButton type="submit" data-testid="submitRequestButton">
                Submit Request
              </SmallButton>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default HolidayRequestForm;
