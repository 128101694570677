import React, { Component } from "react";

import { AppContainer, PageHeading } from "./styledComponents";
import logo from "../../assets/icons/pwg-tree-logo-90-82.png";

class UpdateInProgress extends Component {
  render() {
    return (
      <AppContainer style={{textAlign: "center"}}>
        <img src={logo} alt="PWG logo" />
        <PageHeading>
          Work in progress
        </PageHeading>
        <p>
          Sorry, the leave management app is temporarily unavailable.
        </p>
      </AppContainer>
    )
  }
}

export default UpdateInProgress;
