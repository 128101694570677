import React, { Component } from "react";
import { Flex, Box } from "rebass";
import DatePicker from "react-datepicker";
import "../../css/pwg-react-datepicker.css";

import moment from "moment";

import {
  CheckBoxLabelText,
  FieldContainer,
  FixedWidthLabel,
  RedSpan
} from "../shared/styledComponents";
import { dayCalculationUtilities } from "../shared/logic.js";
import { formValidationHelper } from "../shared/formValidationHelper";
import { datepickerFormat } from "../../constants";

class SickLeaveForm extends Component {
  state = {
    allSickLeaveDays: [],
    isEditingSickLeave: this.props.isEditingSickLeave,
    currentlyEditingSickLeaveId: -1,
    currentSickLeaveData: this.props.currentSickLeaveData,
    dateSubmitted: moment().format("YYYY-MM-DD"),
    sicknessStartDate: undefined,
    sicknessEndDate: undefined,
    returnToWorkDate: undefined,
    sicknessReasons: "",
    isInjuryAtWork: false,
    hasCompletedAccidentBook: false,
    hasReceivedReturnToWorkMeeting: false,
    hasConfirmedUnfitForWork: false,
    hasConfirmedInformationWillBeUsed: false,
    userHasEditedReturnToWorkDate: false,
    isStillOnSickLeave: this.props.isStillOnSickLeave,
    fixedHolidayDays: [],
    status: "Pending",
    inputHighlights: {
      isSicknessStartDateHighlighted: false,
      isSicknessEndDateHighlighted: false,
      isReturnToWorkDayHighlighted: false,
      isSicknessReasonsHighlighted: false,
      isHasConfirmedUnfitForWorkHighlighted: false,
      isHasConfirmedInformationWillBeUsedHighlighted: false
    },
    validationMessages: []
  };

  componentDidMount = async () => {
    let fixedHolidayDaysMoment = this.props.fixedHolidayDays.map(day => {
      return moment(day.date);
    });

    let allSickLeaveDays = dayCalculationUtilities.getArrayOfIndividualLeaveDays(
      this.props.sickLeaves
    );

    await this.setState({
      allSickLeaveDays: allSickLeaveDays,
      fixedHolidayDays: fixedHolidayDaysMoment
    });

    if (this.props.isEditingSickLeave) this.populateForm();

    if (this.props.isStillOnSickLeave) {
      await this.setState({
        sicknessStartDate: moment()
      });
    }
  };

  clearReturnToWorkDate = async () => {
    await this.setState({ returnToWorkDate: undefined });
    this.props.loadingMessages.alert("Return to work date not yet known", 1000);
  };

  clearSicknessEndDate = async () => {
    await this.setState({ sicknessEndDate: undefined });
    this.props.loadingMessages.alert("Sickness end date not yet known");
  };

  handleInputChange = event => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    });

    if (event.currentTarget.name === "sicknessReasons")
      this.removeHighlight("isSicknessReasonsHighlighted");
  };

  handleCheckbox = event => {
    this.setState({
      [event.currentTarget.name]: !this.state[event.currentTarget.name]
    });

    if (event.currentTarget.name === "hasConfirmedUnfitForWork")
      this.removeHighlight("isHasConfirmedUnfitForWorkHighlighted");

    if (event.currentTarget.name === "hasConfirmedInformationWillBeUsed")
      this.removeHighlight("isHasConfirmedInformationWillBeUsedHighlighted");
  };

  handleSubmit = async event => {
    event.preventDefault();
    const validationResult = formValidationHelper.validateSickLeaveForm(
      this.state,
      this.props.loadingMessages.alert,
      this.state.inputHighlights,
      this.state.allSickLeaveDays,
      this.props.isEditingSickLeave,
      this.props.currentSickLeaveData
    );
    if (validationResult.isValid) {
      this.props.submitSickLeave(this.state);
    } else {
      await this.setState({
        inputHighlights: validationResult.inputHighlights,
        validationMessages: validationResult.validationMessages
      });
    }
  };

  populateForm = async () => {
    await this.setState({
      currentlyEditingSickLeaveId: this.props.currentSickLeaveData.id,
      sicknessStartDate: this.getMomentDateForForm(
        this.props.currentSickLeaveData.sicknessStartDate
      ),
      sicknessEndDate: this.getMomentDateForForm(
        this.props.currentSickLeaveData.sicknessEndDate
      ),
      returnToWorkDate: this.getMomentDateForForm(
        this.props.currentSickLeaveData.returnToWorkDate
      ),
      sicknessReasons: this.props.currentSickLeaveData.sicknessReasons,
      isInjuryAtWork: this.getCorrectDataTypeForForm(
        this.props.currentSickLeaveData.isInjuryAtWork
      ),
      hasCompletedAccidentBook: this.getCorrectDataTypeForForm(
        this.props.currentSickLeaveData.hasCompletedAccidentBook
      ),
      hasReceivedReturnToWorkMeeting: this.getCorrectDataTypeForForm(
        this.props.currentSickLeaveData.hasReceivedReturnToWorkMeeting
      ),
      hasConfirmedInformationWillBeUsed: this.getCorrectDataTypeForForm(
        this.props.currentSickLeaveData.hasConfirmedInformationWillBeUsed
      ),
      hasConfirmedUnfitForWork: this.getCorrectDataTypeForForm(
        this.props.currentSickLeaveData.hasConfirmedUnfitForWork
      )
    });
  };

  removeHighlight = async highlight => {
    let updatedInputHighlights = this.state.inputHighlights;
    updatedInputHighlights[highlight] = false;
    await this.setState({ inputHighlights: updatedInputHighlights });
  };

  getCorrectDataTypeForForm = value => {
    if (value === 0 || value === null || value === undefined) return false;
    else if (value === 1) return true;
    else return value;
  };

  getMomentDateForForm = date => {
    if (typeof date === "string") return moment(date);
    else if (date === null) return undefined;
    else return date;
  };

  isDateAvailable = event => {
    if (
      this.state.isEditingSickLeave &&
      event.isSame(this.props.currentSickLeaveData.sicknessStartDate, "days")
    )
      return true;
    return (
      dayCalculationUtilities.isWeekday(event) &&
      dayCalculationUtilities.isDayInRangeOfDays(
        event,
        this.state.allSickLeaveDays
      )
    );
  };

  predictReturnToWorkDateIfNotAlreadySet = async () => {
    if (!this.state.userHasEditedReturnToWorkDate) {
      if (this.state.inputHighlights.isReturnToWorkDayHighlighted)
        this.removeHighlight("isReturnToWorkDayHighlighted");
      await this.setState({
        returnToWorkDate: dayCalculationUtilities.getNextWorkingDay(
          this.state.sicknessEndDate
        )
      });
    }
  };

  preventKeyboardDateInput = e => {
    e.preventDefault();
  };

  updateSicknessStartDate = async event => {
    if (
      this.validateDates(
        event,
        this.state.sicknessEndDate,
        this.state.returnToWorkDate
      )
    ) {
      await this.setState({ sicknessStartDate: event });
      this.removeHighlight("isSicknessStartDateHighlighted");
    }
  };

  updateSicknessEndDate = async event => {
    if (
      this.validateDates(
        this.state.sicknessStartDate,
        event,
        this.state.returnToWorkDate
      )
    ) {
      await this.setState(
        {
          sicknessEndDate: event,
          hasUpdatedSicknessEndDate: true
        },
        this.predictReturnToWorkDateIfNotAlreadySet
      );
      this.removeHighlight("isSicknessEndDateHighlighted");
    }
  };

  updateReturnToWorkDate = async event => {
    if (
      this.validateDates(
        this.state.sicknessStartDate,
        this.state.sicknessEndDate,
        event
      )
    ) {
      await this.setState({
        returnToWorkDate: event,
        userHasEditedReturnToWorkDate: true
      });
      this.removeHighlight("isReturnToWorkDayHighlighted");
    }
  };

  validateDates = (sicknessStartDate, sicknessEndDate, returnToWorkDate) => {
    if (
      !dayCalculationUtilities.isStartDateBeforeOrEqualToEndDate(
        sicknessStartDate,
        sicknessEndDate
      )
    ) {
      this.props.loadingMessages.alert(
        "Start date must be before end date",
        1800
      );
      return false;
    }

    if (
      !dayCalculationUtilities.isStartDateBeforeEndDate(
        sicknessEndDate,
        returnToWorkDate
      )
    ) {
      this.props.loadingMessages.alert(
        "Last day of sickness must be before return to work",
        1800
      );
      return false;
    }

    if (
      !dayCalculationUtilities.isStartDateBeforeEndDate(
        sicknessStartDate,
        returnToWorkDate
      )
    ) {
      this.props.loadingMessages.alert(
        "Start date must be before return to work",
        1800
      );
      return false;
    }

    return true;
  };

  render() {
    return (
      <div style={{ marginRight: "25px" }}>
        <form onSubmit={this.handleSubmit}>
          <input
            type="hidden"
            name="isEditingSickLeave"
            value={this.state.isEditingSickLeave}
          />
          <input
            type="hidden"
            name="currentlyEditingSickLeaveId"
            value={this.state.currentlyEditingSickLeaveId}
          />
          <hr />
          {/*{this.state.validationMessages.length > 0 && <span style={{color:"red"}}>{this.state.validationMessages.map((problem, index) => <div key={index}>{problem}</div>)}</span>}*/}
          <FieldContainer>
            <FixedWidthLabel
              style={formValidationHelper.getLabelStyle(
                this.state.inputHighlights.isSicknessStartDateHighlighted
              )}
            >
              <RedSpan>*</RedSpan> First full day of sickness:
            </FixedWidthLabel>
            <DatePicker
              selected={this.state.sicknessStartDate}
              minDate={moment(`${this.props.currentYear}-01-01`)}
              maxDate={moment(`${this.props.currentYear}-12-31`)}
              onChange={this.updateSicknessStartDate}
              filterDate={this.isDateAvailable}
              dateFormat={datepickerFormat}
              placeholderText="Click to select a date"
              name="sicknessStartDate"
              autoComplete="off"
              className={formValidationHelper.getDatepickerHighlightClassName(
                this.state.inputHighlights.isSicknessStartDateHighlighted
              )}
              onChangeRaw={this.preventKeyboardDateInput}
            />
          </FieldContainer>
          {!this.state.isStillOnSickLeave && (
            <FieldContainer>
              <FixedWidthLabel
                style={formValidationHelper.getLabelStyle(
                  this.state.inputHighlights.isSicknessEndDateHighlighted
                )}
              >
                <RedSpan>*</RedSpan> Last full day of sickness:
              </FixedWidthLabel>
              <DatePicker
                selected={this.state.sicknessEndDate}
                minDate={moment(`${this.props.currentYear}-01-01`)}
                maxDate={moment(`${this.props.currentYear}-12-31`)}
                onChange={this.updateSicknessEndDate}
                filterDate={this.isDateAvailable}
                dateFormat={datepickerFormat}
                placeholderText="Click to select a date"
                name="sicknessStartDate"
                autoComplete="off"
                className={formValidationHelper.getDatepickerHighlightClassName(
                  this.state.inputHighlights.isSicknessEndDateHighlighted
                )}
                onChangeRaw={this.preventKeyboardDateInput}
              />
            </FieldContainer>
          )}
          {!this.state.isStillOnSickLeave && (
            <FieldContainer>
              <FixedWidthLabel
                style={formValidationHelper.getLabelStyle(
                  this.state.inputHighlights.isReturnToWorkDayHighlighted
                )}
              >
                <RedSpan>*</RedSpan> Date returned to work:
              </FixedWidthLabel>
              <DatePicker
                selected={this.state.returnToWorkDate}
                minDate={moment(`${this.props.currentYear}-01-01`)}
                maxDate={moment(`${this.props.currentYear}-12-31`)}
                onChange={this.updateReturnToWorkDate}
                filterDate={this.isDateAvailable}
                dateFormat={datepickerFormat}
                placeholderText="Click to select a date"
                name="sicknessStartDate"
                autoComplete="off"
                className={formValidationHelper.getDatepickerHighlightClassName(
                  this.state.inputHighlights.isReturnToWorkDayHighlighted
                )}
                onChangeRaw={this.preventKeyboardDateInput}
              />
            </FieldContainer>
          )}

          <FieldContainer>
            <FixedWidthLabel
              style={formValidationHelper.getLabelStyle(
                this.state.inputHighlights.isSicknessReasonsHighlighted
              )}
            >
              <CheckBoxLabelText>
                {!this.state.isStillOnSickLeave && <RedSpan>*</RedSpan>} Please
                state briefly your reasons for absence/sickness:
              </CheckBoxLabelText>
            </FixedWidthLabel>
            <textarea
              rows={3}
              cols={60}
              name={"sicknessReasons"}
              style={formValidationHelper.getInputStyle(
                this.state.inputHighlights.isSicknessReasonsHighlighted
              )}
              value={this.state.sicknessReasons}
              onChange={this.handleInputChange}
            />
          </FieldContainer>

          <FieldContainer>
            <input
              id="isInjuryAtWork"
              type="checkbox"
              name="isInjuryAtWork"
              checked={this.state.isInjuryAtWork}
              onChange={this.handleCheckbox}
            />
            <label htmlFor="isInjuryAtWork">
              <CheckBoxLabelText>
                Was this absence as a result of an accident/injury at work?
              </CheckBoxLabelText>
            </label>
          </FieldContainer>

          {!this.state.isStillOnSickLeave && (
            <FieldContainer>
              <input
                id="hasCompletedAccidentBook"
                type="checkbox"
                name="hasCompletedAccidentBook"
                checked={this.state.hasCompletedAccidentBook}
                onChange={this.handleCheckbox}
                style={formValidationHelper.getLabelStyle(
                  this.state.inputHighlights
                    .isHasConfirmedUnfitForWorkHighlighted
                )}
              />
              <label htmlFor="hasCompletedAccidentBook">
                <CheckBoxLabelText>
                  If an accident or injury at work has taken place, have you
                  completed the accident book?
                </CheckBoxLabelText>
              </label>
            </FieldContainer>
          )}

          {!this.state.isStillOnSickLeave && (
            <div>
              <FieldContainer>
                <input
                  id="receivedReturnToWorkMeeting"
                  type="checkbox"
                  name="hasReceivedReturnToWorkMeeting"
                  checked={this.state.hasReceivedReturnToWorkMeeting}
                  onChange={this.handleCheckbox}
                />
                <label htmlFor="receivedReturnToWorkMeeting">
                  Have you received a Return to Work meeting?
                </label>
              </FieldContainer>
              <hr />
              <FieldContainer>
                <RedSpan>*</RedSpan>
                <input
                  id="hasConfirmedUnfitForWork"
                  type="checkbox"
                  name="hasConfirmedUnfitForWork"
                  checked={this.state.hasConfirmedUnfitForWork}
                  onChange={this.handleCheckbox}
                  style={formValidationHelper.getLabelStyle(
                    this.state.inputHighlights
                      .isHasConfirmedUnfitForWorkHighlighted
                  )}
                />
                <label
                  htmlFor="hasConfirmedUnfitForWork"
                  style={formValidationHelper.getLabelStyle(
                    this.state.inputHighlights
                      .isHasConfirmedUnfitForWorkHighlighted
                  )}
                >
                  <CheckBoxLabelText>
                    I confirm that I was unfit for work due to sickness in the
                    period stated above and that the information I have given is
                    correct and complete. I understand that making a false
                    statement may result in disciplinary action being taken and
                    sick pay being terminated.
                  </CheckBoxLabelText>
                </label>
              </FieldContainer>
              <FieldContainer>
                <RedSpan>*</RedSpan>
                <input
                  id="hasConfirmedInformationWillBeUsed"
                  type="checkbox"
                  name="hasConfirmedInformationWillBeUsed"
                  checked={this.state.hasConfirmedInformationWillBeUsed}
                  onChange={this.handleCheckbox}
                  style={formValidationHelper.getLabelStyle(
                    this.state.inputHighlights
                      .isHasConfirmedInformationWillBeUsedHighlighted
                  )}
                />
                <label
                  htmlFor="hasConfirmedInformationWillBeUsed"
                  style={formValidationHelper.getLabelStyle(
                    this.state.inputHighlights
                      .isHasConfirmedInformationWillBeUsedHighlighted
                  )}
                >
                  <CheckBoxLabelText>
                    I understand that this information will be used to record my
                    absence and calculate my entitlement to pay.
                  </CheckBoxLabelText>
                </label>
              </FieldContainer>
              <hr />
            </div>
          )}
          <br />
          <Flex justifyContent="space-between">
            <Box>
              <button type="button" onClick={this.props.toggleForm}>
                Cancel
              </button>
            </Box>
            <Box>
              <button type="submit">Submit Sickness Form</button>
            </Box>
          </Flex>
        </form>
      </div>
    );
  }
}

export default SickLeaveForm;
