import React, { Component } from "react";
import { LargeNumberDisplay, YearSelectorControl } from "../shared/styledComponents";
import { ArrowLeft, ArrowRight } from "styled-icons/fa-solid";
import { theme } from "../../constants";

class YearSelector extends Component {
  render() {
    return (
      <YearSelectorControl>
        <ArrowLeft
          size={28}
          onClick={() => {
            if (this.props.currentYear === 2020)
              this.props.setCurrentYear(2019);
            }
          }
          style={{
            color: this.props.currentYear === 2019 ? '#cdcdcd' : theme.colors.pwg,
            cursor: this.props.currentYear === 2019 ? 'not-allowed' : 'pointer'
          }
        }/>
        <LargeNumberDisplay>{this.props.currentYear}</LargeNumberDisplay>
        <ArrowRight
          size={28}
          onClick={() => {
            if (this.props.currentYear === 2019)
              this.props.setCurrentYear(2020);
            }
          }
          style={{
            color: this.props.currentYear === 2019 ? theme.colors.pwg : '#cdcdcd',
            cursor: this.props.currentYear === 2019 ? 'pointer' : 'not-allowed'
          }
        }/>
      </YearSelectorControl>
    );
  }
}

export default YearSelector;
