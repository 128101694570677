import React, { Component } from "react";

class SickLeaveFormStatusLabel extends Component {
  render() {
    return (
      <span>
        {this.props.statusCode === "Pending" && <span style={{fontWeight:"bold"}}>Pending</span>}
        {this.props.statusCode === "Acknowledged" && "Acknowledged"}
      </span>
    );
  }
}

export default SickLeaveFormStatusLabel;
