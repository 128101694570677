import React from "react";
import moment from "moment";

import PWGCalenderMonthTitle from "./pwgCalendarMonthTitle"
import { SmallButton } from "../shared/styledComponents";

/*
 * This is a custom toolbar above the calendar. It works in a slightly unusual way because
 * we need to pass calendarDate and the setCalendarDate function from the parent component,
 * and this was the only way that we could get the react-big-calendar to pass information
 * to the toolbar component.
 */

const CalendarToolbar = ({ calendarDate, setCalendarDate, setCalendarViewMode, currentViewMode }) => () => (
  <div>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px"
      }}
    >
      <div>
        {currentViewMode === 'day'
        &&
        <div>
          <strong>Leave on {moment(calendarDate).format('MMMM Do YYYY')}</strong>
        </div>
        }
      </div>
      {currentViewMode !== 'day'
        &&
        <PWGCalenderMonthTitle
          setCalendarDate={setCalendarDate}
          calendarDate={calendarDate}
        />
      }
      <div>
        {currentViewMode === 'day'
        &&
          <SmallButton onClick={() => setCalendarViewMode('month')}>Return to month</SmallButton>
        }
      </div>
    </div>
  </div>
);

export default CalendarToolbar;
