import React, { Component } from "react";
import {
  Cell,
  HeadCell,
  Header,
  PageHeading,
  Row,
  Table,
  TableContainer
} from "../shared/styledComponents";
import { database } from "../shared/database";

class AdminYears extends Component {
  state = {
    isLoading: true,
    years: []
  };

  componentDidMount = async () => {
    this.props.loadingMessages.addMessage("Loading years");
    const years = await database.call("helpers/years", {
      token: this.props.token,
      year: this.props.currentYear
    });
    this.props.loadingMessages.removeMessage("Loading years");
    await this.setState({
      isLoading: false,
      years: years.data
    });
    this.props.updateSidebar();
  };

  render() {
    return (
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <PageHeading>Leave Years</PageHeading>
          {this.state.years.length < 1 ? (
            <div>
              <hr />
              <p>{this.state.isLoading ? "Loading" : "No years in database"}</p>
            </div>
          ) : (
            <div>
              <TableContainer>
                <Table>
                  <Header>
                    <tr>
                      <HeadCell>Year</HeadCell>
                      <HeadCell>Year available to users?</HeadCell>
                    </tr>
                  </Header>
                  <tbody>
                    {this.state.years.map(year => {
                      return (
                        <Row key={year.id}>
                          <Cell>{year.year}</Cell>
                          <Cell>
                            {year.isActivated === 1 ? (
                              <span
                                style={{ color: "green", fontWeight: "bold" }}
                              >
                                Open
                              </span>
                            ) : (
                              "Closed"
                            )}
                          </Cell>
                        </Row>
                      );
                    })}
                  </tbody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AdminYears;
