import React from "react";

const PWGCalendarEvent = props => {
  return (
    <div>
      {props.title}
    </div>
  );
};


export default PWGCalendarEvent;
