import React, { Component } from "react";
import moment from "moment";

import BigCalendar from "react-big-calendar";

import { database } from "../shared/database";
import {
  CalendarPlaceholder,
  SmallButton,
  PageHeading, LargeButton, CalendarPopupContainer
} from "../shared/styledComponents";
import { dayCalculationUtilities } from "../shared/logic";
import PWGCalendarEvent from "./pwgCalendarEvent";
import PWGCalendarFilterControl from "./pwgCalendarFilterControl";
import CalendarToolbar from "./pwgCalendarToolbar";

import { CaretDown } from "styled-icons/fa-solid";

import { theme } from "../../constants";
import "../../css/pwg-react-big-calendar.css";
import {confirmAlert} from "react-confirm-alert";

const localizer = BigCalendar.momentLocalizer(moment);

class PWGCalendar extends Component {
  state = {
    allHolidayDays: [],
    ownLeaveRequests: [],
    calendarDate: moment(),
    currentViewMode: "month",
    fixedHolidayDays: [],
    holidayRequests: [],
    isLoading: true,
    isShowAddEventForm: false,
    isShowEditEventForm: false,
    calendarFilterState: this.props.calendarFilterState,
    departments: [],
    isShowFilterControl: false,
    leaveEvents: [],
    selectedDate: moment(),
    selectedEvent: {},
    token: this.props.token
  };

  componentDidMount = () => {
    this.getCalendarData();
    this.props.updateSidebar();
  };

  componentDidUpdate = prevProps => {
    if (this.props.currentYear !== prevProps.currentYear) {
      this.getCalendarData();
    }
  };

  addLeaveRequest = () => {
    this.props.addLeaveRequest(this.state.selectedDate);
    this.props.history.push("/myHoliday/holidayRequestsForm");
  };

  authoriseHolidayRequest = async id => {
    this.props.loadingMessages.addMessage("Authorizing request");
    this.closeCalendarPopUp();

    const authorizeResponse = await database.call(
      "myHolidays/holidayRequest/authorize",
      {
        holidayRequestId: id,
        managerId: this.props.userId,
        token: this.props.token
      }
    );

    this.props.loadingMessages.removeMessage("Authorizing request");

    if (authorizeResponse.data.ROW_COUNT === 1)
      this.getCalendarData(this.state.calendarDate);
    else
      this.props.loadingMessages.alert("Sorry a problem occurred when authorising leave");

    this.props.updateSidebar();
  };

  calculateLeaveEvents = async () => {
    let leaveEvents = [];

    if (this.state.holidayRequests.length > 0) {
      this.state.holidayRequests.map(event => {
        if (this.isHolidayRequestDisplayed(event)) {

          let newEvent = {
            id: event.id,
            isFixedHolidayDay: false,
            userFullName: event.userFullName,
            userId: event.userId,
            title: this.getPWGCalendarEventTitle(event),
            start: new Date(event.startDate),
            end: new Date(moment.utc(event.endDate).format("YYYY-MM-DD")),
            description: event.description,
            rejectionReason: event.rejectionReason,
            status: event.status,
            managerId: event.managerId,
            manager: event.manager
          };

          // @Cleanup - this is a temporary fix to allow correct display in calendar.
          // If the end date is set to midnight, then the last day will not be displayed.
          // This needs to be resolved and we need to make sure that the data returned from
          // the middleware is the same time zone in both dev and prod environments.
          if (newEvent.end.getHours() === 0) {
            newEvent.end = new Date(
              moment
                .utc(event.endDate)
                .add(1, "days")
                .format("YYYY-MM-DD")
            );
          }

          leaveEvents.push(newEvent);
        }
        return null;
      });
    }

    if (this.state.fixedHolidayDays.length > 0) {
      this.state.fixedHolidayDays.map(event => {
        leaveEvents.push({
          id: event.id,
          isFixedHolidayDay: true,
          isPublicHoliday: event.isPublicHoliday,
          userId: null,
          name: event.name,
          title:
            event.isPublicHoliday === 1
              ? event.name + " (public holiday)"
              : "Mandatory Leave",
          start: new Date(event.date),
          end: new Date(event.date),
          status:
            event.isPublicHoliday === 1
              ? "publicHoliday"
              : "mandatoryHolidayDay",
          managerId: -1,
          manager: -1
        });
        return null;
      });
    }

    await this.setState({
      leaveEvents: leaveEvents
    });
  };

  closeCalendarPopUp = async () => {
    await this.setState({
      isShowAddEventForm: false,
      isShowEditEventForm: false
    });
  };

  deleteHolidayRequest = async id => {
    // this.props.loadingMessages.alert("This will delete holiday request id " + id, 2000);

    confirmAlert({
      message: "Are you sure you want to delete the holiday request?",
      buttons: [
        {
          label: "OK",
          onClick: async () => {
            this.props.loadingMessages.addMessage("Deleting leave request");

            const deleteHolidayRequestResponse = await database.call(
              "myHolidays/holidayRequest/delete",
              {
                holidayRequestId: id,
                token: this.props.token
              }
            );

            this.props.loadingMessages.removeMessage("Deleting leave request");

            if (deleteHolidayRequestResponse && deleteHolidayRequestResponse.data.ROW_COUNT === 1)
              this.getCalendarData(this.state.calendarDate);
            else
              this.props.loadingMessages.alert("Sorry a problem occurred when deleting leave");

            this.props.updateSidebar();
          }
        },
        {
          label: "Cancel"
        }
      ]
    });
    this.closeCalendarPopUp();
  };

  getEventPopUpButtons = event => {
    let buttonsToDisplay = {
      isShowDeleteButton: false,
      isShowAuthoriseButton: false,
      isShowRejectButton: false
    };

    if (event.userId === this.props.userId && (event.status === "pending" || event.status === "rejected"))
      buttonsToDisplay.isShowDeleteButton = true;

    if (event.managerId === this.props.userId) {
      if (event.status === 'pending') {
        buttonsToDisplay.isShowAuthoriseButton = true;
        buttonsToDisplay.isShowRejectButton = true;
      }
      else if (event.status === 'authorized')
        buttonsToDisplay.isShowRejectButton = true;
      else if (event.status === 'rejected')
        buttonsToDisplay.isShowAuthoriseButton = true;
    }

    return buttonsToDisplay;
  };

  getCalendarData = async (calendarDate) => {
    this.props.loadingMessages.addMessage(
      "Loading " + this.props.currentYear + " data for calendar"
    );

    if (!calendarDate) {
      if (moment().year() !== this.props.currentYear)
        this.setState({
          calendarDate: moment(this.props.currentYear + "-01-01").toDate()
        });
      else this.setState({calendarDate: moment().toDate()});
    }

    const holidayRequestsForCalendar = await database.call(
      "myHolidays/holidayRequest/getAll",
      {
        startDate: this.props.currentYear + "-01-01",
        endDate: this.props.currentYear + "-12-31",
        token: this.props.token
      }
    );

    const departments = await database.call(
      "helpers/departments/",
      {
        token: this.props.token
      }
    );

    const fixedHolidayDays = await database.call(
      "myHolidays/fixedHolidayDays",
      {
        year: this.props.currentYear,
        token: this.props.token
      }
    );

    this.props.loadingMessages.removeMessage(
      "Loading " + this.props.currentYear + " data for calendar"
    );

    let allHolidayDays = [];

    let fixedHolidayDaysMoment = dayCalculationUtilities.convertDateStringArrayIntoMomentArray(
      fixedHolidayDays.data
    );

    let userHolidayRequests = holidayRequestsForCalendar.data.filter(event => {
      if (event.userId === this.props.userId) return event;
      else return null;
    });

    let userHolidayDays = dayCalculationUtilities.getArrayOfIndividualLeaveDays(
      userHolidayRequests
    );

    allHolidayDays.push(...fixedHolidayDaysMoment);
    allHolidayDays.push(...userHolidayDays);

    await this.setState(
      {
        allHolidayDays: allHolidayDays,
        holidayRequests: holidayRequestsForCalendar.data,
        fixedHolidayDays: fixedHolidayDays.data,
        departments: departments.data,
        isLoading: false
      },
      this.calculateLeaveEvents
    );
  };

  getDepartmentName = id => {
    for(let i = 0; i < this.state.departments.length; i++) {
      if (this.state.departments[i].id === id)
        return this.state.departments[i].description;
    }
  };

  getEventDescriptionForPopUp = event => {

    let msg = "";

    if (event.isFixedHolidayDay) {
      if (event.isPublicHoliday)
        msg += "This is a public holiday which will not be deducted from your leave entitlement.";
      else
        msg += "This is a mandatory leave day which will be deducted from your leave entitlement.";
    }

    if (event.userId === this.props.userId) {
      msg += `
      `;
      if (event.status === "pending")
        msg += "This leave request is pending. Your manager has not approved or rejected it.";
      else if (event.status === "authorized")
        msg += "This leave request has been authorised by your manager.";
      else if (event.status === "rejected")
        msg += `This leave request has been rejected by your manager for the following reasons: "${event.rejectionReason}"`;
    }

    return msg;
  };

  getEventTitleForPopUp = event => {
    if (event.isFixedHolidayDay) {
      let html = event.name;
      html += event.isPublicHoliday ? ' (Public Holiday)' : ' (Mandatory Holiday Day)';
      return html;
    }
    else {
      let html = 'Leave request';
      if (event.userId !== this.props.userId)
        html += ` for ${event.userFullName}`;
      html += ` (${event.status})`;
      return html;
    }
  };

  getPWGCalendarEventStyle = e => {
    let style = {
      borderRadius: "5px",
      opacity: 0.8,
      color: "#ffffff",
      border: "0px",
      display: "block",
      cursor: "pointer",
      backgroundColor: theme.colors.leaveCalendar.otherPersonLeaveRequest
    };
    if (e.status === "publicHoliday")
      style.backgroundColor = theme.colors.leaveCalendar.publicHoliday;
    else if (e.status === "mandatoryHolidayDay")
      style.backgroundColor = theme.colors.leaveCalendar.mandatoryHolidayDay;
    else if (e.status === "rejected")
      style.backgroundColor = theme.colors.leaveCalendar.rejectedLeaveRequest;
    else if (e.status === "pending") {
      style.color = "#ffff00";
      style.border = "2px solid #000000";
    }
    if (moment(e.start).year() !== this.props.currentYear) style.opacity = 0.3;
    return {
      style: style
    };
  };

  getPWGCalendarDayStyle = e => {
    let todaysDate = moment().isSame(e, "day");
    if (todaysDate) return { className: "rbc-today" };

    if (this.isDateAvailable(moment(e)))
      return { className: "pwgCalendarDay pwgCalendarDayFuture" };
    else return { className: "pwgCalendarDayPast" };
  };

  getPWGCalendarEventTitle = e => {
    let title = "";
    title += e.userFullName;
    if (e.holidayDayType === "AM only") title = "(AM) " + title;
    else if (e.holidayDayType === "PM only") title = "(PM) " + title;
    if (e.status === "pending") title += " (requested)";
    if (e.status === "rejected") title += " (not authorized)";
    return title;
  };

  getPWGCalendarFilterStateDescription = () => {
    if (this.state.calendarFilterState.isShowMyLeaveOnly)
      return 'My leave only';
    else if (this.state.calendarFilterState.isShowMyDepartmentOnly)
      return this.getDepartmentName(this.state.calendarFilterState.displayedDepartments[0]);
    else {
      const departmentsCount = this.state.calendarFilterState.displayedDepartments.length;
      if (departmentsCount === this.state.departments.length)
        return 'All departments';
      else if (departmentsCount === 1)
        return this.getDepartmentName(this.state.calendarFilterState.displayedDepartments[0]);
      else
        return departmentsCount + ' Departments';
    }
  };

  isDateAvailable = e => {
    return (
      dayCalculationUtilities.isWeekday(e) &&
      dayCalculationUtilities.isDayInRangeOfDays(
        e,
        this.state.allHolidayDays
      ) &&
      moment(e).year() === this.props.currentYear
    );
  };

  isHolidayRequestDisplayed = event => {
    if (this.state.calendarFilterState.isShowMyLeaveOnly && event.userId === this.props.userId)
      return true;

    if (this.state.calendarFilterState.isShowMyDepartmentOnly && event.departmentId === this.props.departmentId)
      return true;

    for (let i = 0; i < this.state.calendarFilterState.displayedDepartments.length; i++) {
      if (event.departmentId === this.state.calendarFilterState.displayedDepartments[i])
        return true;
    }

    return false;
  };

  rejectHolidayRequest = async id => {
    const rejectionPrompt = prompt("Please enter reason for rejection leave request");

    if (rejectionPrompt === null || rejectionPrompt === "") {
      confirmAlert({
        message: "You cannot reject a holiday without stating a reason",
        buttons: [
          {
            label: "OK"
          }
        ]
      });
      return false;
    } else {
      this.props.loadingMessages.addMessage("Authorizing request");
      this.closeCalendarPopUp();
    }

    const rejectResponse = await database.call(
      "myHolidays/holidayRequest/reject",
      {
        holidayRequestId: id,
        managerId: this.props.userId,
        token: this.props.token,
        rejectionReason: rejectionPrompt
      }
    );

    this.props.loadingMessages.removeMessage("Rejecting request");

    if (rejectResponse.data.ROW_COUNT === 1)
      this.getCalendarData(this.state.calendarDate);
    else
      this.props.loadingMessages.alert("Sorry a problem occurred when rejecting leave");

    this.props.updateSidebar();
  };

  toggleFilter = async () => {
    await this.setState({
      isShowFilterControl: !this.state.isShowFilterControl
    });
  };

  selectEvent = async e => {
    //console.log("selectEvent", e);

    await this.setState({
      selectedEvent: e,
      isShowEditEventForm: true
    });
  };

  setCalendarViewMode = async e => {
    await this.setState({ currentViewMode: e });
  };

  setCalendarDate = async e => {
    await this.setState({ calendarDate: e });
  };

  updateCalendarFilter = async newCalendarFilterState => {
    this.props.loadingMessages.addMessage(
      "Saving calendar view preferences"
    );

    await this.setState({
      calendarFilterState: newCalendarFilterState
    });

    this.calculateLeaveEvents();

    const setCalendarFilterStateResponse = await database.call(
      "helpers/setCalendarFilterState",
      {
        userId: this.props.userId,
        calendarFilterState: JSON.stringify(newCalendarFilterState),
        token: this.props.token
      }
    );

    if (setCalendarFilterStateResponse.data.ROW_COUNT !== 1)
      this.props.loadingMessages.alert("Problem saving view preferences", 3000);

    this.props.loadingMessages.removeMessage(
      "Saving calendar view preferences"
    );
  };

  zoomToDay = async e => {
    console.log("onDrillDown", e);
    await this.setState({
      currentViewMode: "day",
      calendarDate: e
    });
  };

  selectSlot = async e => {
    console.log("selectSlot", e);
    if (this.isDateAvailable(moment(e.start))) {
      await this.setState({
        isShowAddEventForm: true,
        selectedDate: moment(e.start)
      });
    } else {
      if (dayCalculationUtilities.isWeekend(e.start))
        this.props.loadingMessages.alert(
          "Cannot request leave on a weekend!",
          2000
        );
      else if (moment(e.start).year() !== this.props.currentYear)
        this.props.loadingMessages.alert(
          "Please select this year before requesting leave on this day",
          2000
        );
      else
        this.props.loadingMessages.alert(
          "You have leave booked or requested on this day",
          2000
        );
    }
  };

  render() {
    const calendarDate = this.state.calendarDate;
    const setCalendarDate = this.setCalendarDate;
    const currentViewMode = this.state.currentViewMode;
    const setCalendarViewMode = this.setCalendarViewMode;

    let filterStateDescription = " ";
    if (this.state.calendarFilterState !== null) {
      filterStateDescription = this.getPWGCalendarFilterStateDescription();
    }

    return (
      <div style={{ width: "700px", flexGrow: 1, marginRight: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <PageHeading>
            {this.props.currentYear} Holiday Calendar
          </PageHeading>
          {!this.state.isLoading && (
            <div>
              {filterStateDescription}
              <SmallButton
                style={{ marginLeft: "15px", marginRight: "15px" }}
                onClick={this.toggleFilter}
              >
                Show on calendar <CaretDown size={12} />
              </SmallButton>
            </div>
          )}
        </div>
        <hr style={{ marginBottom: "10px" }} />
        {this.state.isLoading && <CalendarPlaceholder />}
        {this.state.isShowAddEventForm && (
          <CalendarPopupContainer>
            <strong>{this.state.selectedDate.format('dddd Do MMMM YYYY')}</strong>
            <LargeButton style={{marginTop: "25px"}} onClick={this.addLeaveRequest}>
              Add leave request starting on this day
            </LargeButton>
            <div>
              <SmallButton style={{marginTop: "35px"}} onClick={this.closeCalendarPopUp}>
                Cancel
              </SmallButton>
            </div>
          </CalendarPopupContainer>
        )}
        {this.state.isShowEditEventForm && (
          <CalendarPopupContainer>
            <p><strong>{this.getEventTitleForPopUp(this.state.selectedEvent)}</strong></p>
            <p>{this.getEventDescriptionForPopUp(this.state.selectedEvent)}</p>
            <div style={{display: "flex", justifyContent: "space-between", alignContent: "center"}}>
              <SmallButton onClick={this.closeCalendarPopUp}>Close</SmallButton>
              <div>
                {
                  this.getEventPopUpButtons(this.state.selectedEvent).isShowDeleteButton
                  &&
                  <SmallButton
                    onClick={() => this.deleteHolidayRequest(this.state.selectedEvent.id)}
                    style={{marginLeft:"10px"}}
                  >
                    Delete
                  </SmallButton>
                }
                {
                  this.getEventPopUpButtons(this.state.selectedEvent).isShowAuthoriseButton
                  &&
                  <SmallButton
                    onClick={() => this.authoriseHolidayRequest(this.state.selectedEvent.id)}
                    style={{marginLeft:"10px"}}
                  >
                    Authorise
                  </SmallButton>
                }
                {
                  this.getEventPopUpButtons(this.state.selectedEvent).isShowRejectButton
                  &&
                  <SmallButton
                    onClick={() => this.rejectHolidayRequest(this.state.selectedEvent.id)}
                    style={{marginLeft:"10px"}}
                  >
                    Reject
                  </SmallButton>
                }
              </div>
            </div>
          </CalendarPopupContainer>
        )}
        {(!this.state.isLoading && this.state.leaveEvents.length) > 0 && (
          <BigCalendar
            style={{ cursor: "pointer", height: "auto", marginBottom: "30px" }}
            localizer={localizer}
            events={this.state.leaveEvents}
            startAccessor="start"
            endAccessor="end"
            selectable={true}
            onSelectEvent={this.selectEvent}
            onSelectSlot={this.selectSlot}
            onDrillDown={this.zoomToDay}
            onView={this.setCalendarViewMode}
            date={this.state.calendarDate}
            onNavigate={this.setCalendarDate}
            view={this.state.currentViewMode}
            views={["month", "week", "day"]}
            components={{
              event: PWGCalendarEvent,
              toolbar: CalendarToolbar({
                calendarDate,
                setCalendarDate,
                setCalendarViewMode,
                currentViewMode
              })
            }}
            eventPropGetter={this.getPWGCalendarEventStyle}
            dayPropGetter={this.getPWGCalendarDayStyle}
          />
        )}
        {this.state.isShowFilterControl && (
          <PWGCalendarFilterControl
            userDepartmentId={this.props.departmentId}
            departments={this.state.departments}
            calendarFilterState={this.state.calendarFilterState}
            updateCalendarFilter={this.updateCalendarFilter}
            toggleFilter={this.toggleFilter}
          />
        )}
      </div>
    );
  }
}

export default PWGCalendar;
