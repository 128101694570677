import React, { Component } from "react";

import moment from "moment";

import { Flex, Box } from "rebass";
import DatePicker from "react-datepicker";
import "../../css/pwg-react-datepicker.css";

import {
  SmallButton,
  CheckBoxLabelText,
  FieldContainer,
  FixedWidthLabel,
  FixedWidthInput,
  RedSpan
} from "../shared/styledComponents";
import { dayCalculationUtilities } from "../shared/logic";
import { formValidationHelper } from "../shared/formValidationHelper";
import { datepickerFormat } from "../../constants";

class FixedHolidayDaysForm extends Component {
  state = {
    isPublicHoliday: false,
    name: "",
    fixedHolidayDate: undefined,
    inputHighlights: {
      isFixedHolidayDayNameHighlighted: false,
      isFixedHolidayDateHighlighted: false
    }
  };

  handleCheckbox = async () => {
    await this.setState({
      isPublicHoliday: !this.state.isPublicHoliday
    });
  };

  updateDate = async event => {
    await this.setState({
      fixedHolidayDate: event,
      inputHighlights: {
        isFixedHolidayDayNameHighlighted: this.state
          .isFixedHolidayDayNameHighlighted,
        isFixedHolidayDateHighlighted: false
      }
    });
  };

  handleInputChange = async event => {
    await this.setState({
      [event.currentTarget.name]: event.currentTarget.value,
      inputHighlights: {
        isFixedHolidayDayNameHighlighted: false,
        isFixedHolidayDateHighlighted: this.state.isFixedHolidayDateHighlighted
      }
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const validationResult = formValidationHelper.validateAddFixedHolidayDayForm(
      this.state,
      this.props.loadingMessages.alert,
      this.state.inputHighlights,
      this.props.fixedHolidayDays
    );

    if (validationResult.isValid)
      this.props.submitNewFixedHolidayDay(this.state);
    else
      await this.setState({
        inputHighlights: validationResult.inputHighlights
      });
  };

  isDateAvailable = event => {
    for (let i = 0; i < this.props.fixedHolidayDays.length; i++) {
      if (this.props.fixedHolidayDays[i].isSame(event, "days")) return false;
    }
    return dayCalculationUtilities.isWeekday(event);
  };

  render() {
    let holidayDayNameInputStyle = formValidationHelper.getInputStyle(
      this.state.inputHighlights.isFixedHolidayDayNameHighlighted
    );
    holidayDayNameInputStyle.width = "250px";

    return (
      <div>
        <br />
        <Flex>
          <form onSubmit={this.handleSubmit}>
            <Box>
              <FieldContainer>
                <FixedWidthLabel
                  style={formValidationHelper.getLabelStyle(
                    this.state.inputHighlights.isFixedHolidayDayNameHighlighted
                  )}
                >
                  <RedSpan>*</RedSpan> Holiday Day Name:
                </FixedWidthLabel>
                <FixedWidthInput
                  style={holidayDayNameInputStyle}
                  type="text"
                  name={"name"}
                  value={this.state.name}
                  onChange={this.handleInputChange}
                />
              </FieldContainer>
              <FieldContainer>
                <FixedWidthLabel
                  style={formValidationHelper.getLabelStyle(
                    this.state.inputHighlights.isFixedHolidayDateHighlighted
                  )}
                >
                  <RedSpan>*</RedSpan> Date:
                </FixedWidthLabel>
                <DatePicker
                  selected={this.state.fixedHolidayDate}
                  minDate={moment(`${this.props.currentYear}-01-01`)}
                  maxDate={moment(`${this.props.currentYear}-12-31`)}
                  onChange={this.updateDate}
                  filterDate={this.isDateAvailable}
                  dateFormat={datepickerFormat}
                  placeholderText="Click to select a date"
                  name="sicknessStartDate"
                  autoComplete="off"
                  className={formValidationHelper.getDatepickerHighlightClassName(
                    this.state.inputHighlights.isFixedHolidayDateHighlighted
                  )}
                  onChangeRaw={this.preventKeyboardDateInput}
                />
              </FieldContainer>
              <FieldContainer>
                <div style={{ width: "225px" }} />
                <div style={{ width: "325px" }}>
                  <input
                    id="isPublicHoliday"
                    type="checkbox"
                    checked={this.state.isPublicHoliday}
                    onChange={this.handleCheckbox}
                  />
                  <label htmlFor="isPublicHoliday">
                    <CheckBoxLabelText> Public Holiday</CheckBoxLabelText>
                  </label>
                </div>
              </FieldContainer>
            </Box>
            <br />
            <Box align="right">
              <SmallButton style={{ marginRight: "63px" }} type="submit">
                Save New Fixed Holiday Day
              </SmallButton>
            </Box>
          </form>
        </Flex>
        <br />
      </div>
    );
  }
}

export default FixedHolidayDaysForm;
