import React, { Component } from "react";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";

import "../../css/pwg-react-confirm-alert.css";

import StatusLabel from "../shared/statusLabel";
import RequestActionButton from "../shared/requestActionButton";
import {
  Table,
  Header,
  HeadCell,
  Cell,
  Row,
  TableContainer,
  PageHeading
} from "../shared/styledComponents";
import {
  dayCalculationUtilities,
  returnOrderedArrayOfDates
} from "../shared/logic";
import { database } from "../shared/database";

class StaffHolidayRequests extends Component {
  state = {
    isLoading: true,
    data: []
  };

  componentDidMount = () => {
    this.getStaffHolidayRequestsData();
  };

  componentDidUpdate = prevProps => {
    if (this.props.currentYear !== prevProps.currentYear) {
      this.getStaffHolidayRequestsData();
    }
  };

  getStaffHolidayRequestsData = async () => {
    this.props.loadingMessages.addMessage("Loading my team's holiday requests");

    const holidayRequestsData = await database.call(
      "myHolidays/holidayRequest/getAllByManager",
      {
        managerId: this.props.userId,
        token: this.props.token,
        year: this.props.currentYear
      }
    );

    // console.log("holidayRequestsData", holidayRequestsData);
    this.props.loadingMessages.removeMessage(
      "Loading my team's holiday requests"
    );
    const orderedArrayOfHolidayRequests = returnOrderedArrayOfDates(
      holidayRequestsData.data
    );
    await this.setState({
      isLoading: false,
      data: orderedArrayOfHolidayRequests
    });
    this.props.updateSidebarPendingActionCounts();
  };

  authorize = async id => {
    this.props.loadingMessages.addMessage("Authorising request");

    const authorizeResponse = await database.call(
      "myHolidays/holidayRequest/authorize",
      {
        holidayRequestId: id,
        managerId: this.props.userId,
        token: this.props.token
      }
    );

    this.props.loadingMessages.removeMessage("Authorising request");

    if (authorizeResponse.data.ROW_COUNT === 1)
      this.updateStatusInComponentState(id, "authorized");
    this.getStaffHolidayRequestsData();
  };

  reject = async id => {
    const rejectionPrompt = prompt(
      "Please enter reason for rejection leave request"
    );

    if (rejectionPrompt === null || rejectionPrompt === "") {
      confirmAlert({
        message: "You cannot reject a holiday without stating a reason",
        buttons: [
          {
            label: "OK"
          }
        ]
      });
      return false;
    } else {
      this.props.loadingMessages.addMessage("Rejecting request");
    }

    const rejectResponse = await database.call(
      "myHolidays/holidayRequest/reject",
      {
        holidayRequestId: id,
        managerId: this.props.userId,
        token: this.props.token,
        rejectionReason: rejectionPrompt
      }
    );

    this.props.loadingMessages.removeMessage("Rejecting request");

    if (rejectResponse.data.ROW_COUNT === 1)
      this.updateStatusInComponentState(id, "rejected");
    this.getStaffHolidayRequestsData();
  };

  updateStatusInComponentState = async (id, status) => {
    let updatedData = this.state.data;
    for (let i = 0; i < updatedData.length; i++) {
      if (updatedData[i].id === id) {
        updatedData[i].status = status;
      }
    }
    await this.setState({ data: updatedData });
  };

  render() {
    if (this.state.isLoading) {
      return <div>Loading..</div>;
    }

    return (
      <div style={{ paddingBottom: "15px", width: "100%" }}>
        <div style={{ marginTop: "10px" }}>
          <PageHeading>Staff Holiday Requests</PageHeading>
        </div>

        {this.state.data.length <= 0 && (
          <span>No staff holiday requests at this time</span>
        )}
        {this.state.data.length > 0 && (
          <TableContainer>
            <Table>
              <Header>
                <tr>
                  <HeadCell>Name</HeadCell>
                  <HeadCell>Start date</HeadCell>
                  <HeadCell>End date</HeadCell>
                  <HeadCell>Days</HeadCell>
                  <HeadCell>Status</HeadCell>
                  <HeadCell>Details</HeadCell>
                  <HeadCell />
                </tr>
              </Header>
              <tbody>
                {this.state.data.map(request => {
                  return (
                    <Row key={request.id}>
                      <Cell>{request.userFullName}</Cell>
                      <Cell>
                        {dayCalculationUtilities.getFormattedDate(
                          moment(request.startDate)
                        )}
                      </Cell>
                      <Cell>
                        {dayCalculationUtilities.getFormattedDate(
                          moment(request.endDate)
                        )}
                      </Cell>
                      <Cell>
                        {request.holidayDayType ? (
                          request.holidayDayType === "All day" ? (
                            <span>{request.days}</span>
                          ) : (
                            <span>0.5 ({request.holidayDayType})</span>
                          )
                        ) : (
                          1
                        )}
                      </Cell>
                      <Cell>
                        {" "}
                        <StatusLabel statusCode={request.status} />
                      </Cell>

                      <Cell>
                        {request.description && `Notes: ${request.description}`}
                        <p>
                          {request.rejectionReason &&
                            `Rejection: ${request.rejectionReason}`}
                        </p>
                      </Cell>
                      <Cell style={{ paddingLeft: "10px" }}>
                        {request.status === "pending" && (
                          <span>
                            <RequestActionButton
                              label="Authorise"
                              id={request.id}
                              status="authorized"
                              action={this.authorize}
                            />
                            <RequestActionButton
                              label="Reject"
                              id={request.id}
                              status="rejected"
                              action={this.reject}
                            />
                          </span>
                        )}
                        {request.status === "rejected" && (
                          <span>
                            <RequestActionButton
                              label="Authorise"
                              id={request.id}
                              status="authorized"
                              action={this.authorize}
                            />
                          </span>
                        )}
                        {request.status === "authorized" && (
                          <span>
                            <RequestActionButton
                              label="Reject"
                              id={request.id}
                              status="rejected"
                              action={this.reject}
                            />
                          </span>
                        )}
                      </Cell>
                    </Row>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  }
}

export default StaffHolidayRequests;
