import React, { Component } from "react";
import { Box } from "rebass";
import styled from "styled-components";

import { icons } from "../../assets/icons/icons";
import { theme } from "../../constants";

const Container = styled(Box)`
  display: inline-block;
  position: relative;
  cursor: help;
`;

const Content = styled(Box)`
  width: 260px;
  position: absolute;
  bottom: 30px;
  padding: 0px;
  border: 1px solid ${theme.colors.border};
  background-color: ${theme.colors.toolTipBackground};
`;

const Icon = styled.img`
  cursor: help;
  line-height: 14px;
`;

class ToolTip extends Component {
  state = { isContentToggled: false };

  handleToggle = isContentToggled => () => {
    this.setState({ isContentToggled });
  };

  render() {
    return (
      <Container mx={2}>
        {this.state.isContentToggled && (
          <Content fontSize={0} style={{ color: "black" }}>
            {this.props.children}
          </Content>
        )}
        <Icon
          src={icons.helpIcon}
          onMouseOver={this.handleToggle(true)}
          onMouseOut={this.handleToggle(false)}
        />
      </Container>
    );
  }
}

export default ToolTip;
