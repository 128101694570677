import { Link } from "react-router-dom";
import React from "react";
import { theme } from "../../constants";
import styled from "styled-components";

export const SideMenuDiv = styled.div`
  background-color: ${theme.colors.sideMenu.background};
  border-right: 1px solid #bfbfbf;
  height: 100%;
  font-size: 13px;
  width: 218px;
  /*min-width: 162px;*/
  /*max-width: 218px;*/
`;

export const SideMenuWhiteDiv = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #989b82;
  margin-top: 0px;
  padding: 8px;
  padding-left: 15px;
`;

// export const SubSideMenu = styled.div`
//   display: none;
//   position: absolute;
//   min-width: 160px;
//   padding: 12px 16px;
//   z-index: 1;
// `;

export const SideMenuLinkStyle = styled(Link)`
  padding: 8px;
  padding-left: 15px;
  display: block;
  border-bottom: 1px solid #989b82;
  border-width: thin;
  border-color: gray;
  color: #202020;
  text-decoration: none;
  &:hover {
    text-decoration: ${props => (props.params.isSelected ? "none" : "none")};
  }
  font-weight: ${props => (props.params.isSelected ? "bold" : "normal")};
  background-color: ${props =>
    props.params.isSelected ? "#c7cf93" : "#e0e4c2"};
`;

// export const SideMenuAnchor = styled.a`
//   display: block;
//   color: #202020;
//   padding: 8px 8px 8px 15px;
//   border-bottom: 1px solid #989b82;
//   text-decoration: none;
//   background-color: ${props => (props.isSelected ? "#c7cf93" : "#e0e4c2")};
//   &:hover {
//     text-decoration: ${props => (props.isSelected ? "none" : "none")};
//   }
//   font-weight: ${props => (props.isSelected ? "bold" : "normal")};
// `;

export const SideMenuLink = props => {
  return (
    <SideMenuLinkStyle
      id={props.id}
      to={props.to}
      children={props.children}
      params={{ isSelected: props.isSelected }}
    />
  );
};

// export const SubSideMenuLink = styled(Link)`
//   padding: 8px 50px 8px 16px;
//   display: block;
//   border-bottom: 1px solid #989b82;
//   border-width: thin;
//   border-color: gray;
//   color: #202020;
//   text-decoration: none;
//   &:hover {
//     text-decoration: underline;
//   }
//   background-color: #fff8cb;
// `;

export const Icon = styled.img`
  cursor: pointer;
`;
