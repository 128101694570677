import React, { Component } from "react";

import { confirmAlert } from "react-confirm-alert";
import "../../css/pwg-react-confirm-alert.css";

import { database } from "../shared/database";
import { dayCalculationUtilities } from "../shared/logic";
import { returnOrderedArrayOfDates } from "../shared/logic";

import SickLeaveForm from "./sickLeaveForm";
import SickLeaveFormSelector from "./sickLeaveFormSelector";
import SickLeaveFormStatusLabel from "../shared/sickLeaveFormStatusLabel";
import {
  Cell,
  HeadCell,
  Header,
  PageHeading,
  Row,
  SmallButton,
  Table,
  TableContainer
} from "../shared/styledComponents";
import SickLeaveFormDatesLabel from "../shared/sickLeaveFormDatesLabel";
import SickLeaveFormInformationLabel from "../shared/sickLeaveFormInformationLabel";

class MySickLeaves extends Component {
  state = {
    isEditingSickLeave: false,
    currentSickLeaveData: null,
    isLoading: true,
    isShowForm: false,
    isShowFormSelector: false,
    isStillOnSickLeave: false,
    sickLeaves: [],
    fixedHolidayDays: [],
    userId: this.props.userId,
    token: this.props.token
  };
  componentDidMount = () => {
    this.getSickLeavesData();
    this.props.updateSidebar();
  };

  componentDidUpdate = prevProps => {
    if (this.props.currentYear !== prevProps.currentYear) {
      this.getSickLeavesData();
    }
  };

  getSickLeavesData = async () => {
    this.props.loadingMessages.addMessage("Loading sick leave");
    const sickLeaves = await this.getSickLeavesFromDatabase();

    const fixedHolidayDays = await database.call(
      "myHolidays/fixedHolidayDays",
      {
        year: this.props.currentYear,
        token: this.props.token
      }
    );

    this.props.loadingMessages.removeMessage("Loading sick leave");
    const orderedArrayOfSickLeaves = returnOrderedArrayOfDates(sickLeaves.data);
    await this.setState({
      isLoading: false,
      sickLeaves: orderedArrayOfSickLeaves,
      fixedHolidayDays: fixedHolidayDays.data
    });
  };

  deleteSickLeave = sickLeaveId => {
    confirmAlert({
      message:
        "Are you sure you want to permanently delete this sick leave form? You cannot undo this action.",
      buttons: [
        {
          label: "OK",
          onClick: async () => {
            this.props.loadingMessages.addMessage("Deleting sick leave");

            const deleteSickLeaveResponse = await database.call(
              "sickLeave/delete",
              {
                sickLeaveId: sickLeaveId,
                token: this.props.token
              }
            );

            this.props.loadingMessages.removeMessage("Deleting sick leave");
            if (deleteSickLeaveResponse.data.ROW_COUNT === 1) {
              this.reloadSickLeavesFromDatabase();
              this.props.updateSidebar();
            }
          }
        },
        {
          label: "Cancel"
        }
      ]
    });
  };

  editSickLeave = async sickLeave => {
    await this.setState({
      isShowForm: true,
      isEditingSickLeave: true,
      currentSickLeaveData: {
        id: sickLeave.id,
        sicknessStartDate: sickLeave.sicknessStartDate,
        sicknessEndDate: sickLeave.sicknessEndDate,
        returnToWorkDate: sickLeave.returnToWorkDate,
        sicknessReasons: sickLeave.sicknessReasons,
        isInjuryAtWork: sickLeave.isInjuryAtWork,
        hasCompletedAccidentBook: sickLeave.hasCompletedAccidentBook,
        hasReceivedReturnToWorkMeeting:
          sickLeave.hasReceivedReturnToWorkMeeting,
        hasConfirmedUnfitForWork: sickLeave.hasConfirmedUnfitForWork,
        hasConfirmedInformationWillBeUsed:
          sickLeave.hasConfirmedInformationWillBeUsed
      }
    });
  };

  getSickLeavesFromDatabase = async () => {
    const sickLeaves = await database.call("sickLeave/getAllByUser", {
      userId: this.props.userId,
      token: this.props.token,
      year: this.props.currentYear
    });
    return sickLeaves;
  };

  reloadSickLeavesFromDatabase = async () => {
    const sickLeaves = await this.getSickLeavesFromDatabase();
    await this.setState({
      sickLeaves: sickLeaves.data
    });
  };

  showCompleteSickLeaveForm = async () => {
    await this.setState({
      isShowForm: true,
      isShowFormSelector: false,
      isStillOnSickLeave: false
    });
  };

  showCurrentSickLeaveForm = async () => {
    await this.setState({
      isShowForm: true,
      isShowFormSelector: false,
      isStillOnSickLeave: true
    });
  };

  submitSickLeave = async sickLeave => {
    let savingMessage =
      sickLeave.isEditingSickLeave === true
        ? "Updating Sick Leave"
        : "Saving sick leave";
    this.props.loadingMessages.addMessage(savingMessage);

    if (sickLeave.isEditingSickLeave) {
      const deleteSickLeaveResponse = await database.call("sickLeave/delete", {
        sickLeaveId: sickLeave.currentlyEditingSickLeaveId,
        token: this.props.token
      });

      if (deleteSickLeaveResponse.data.ROW_COUNT === 1)
        this.reloadSickLeavesFromDatabase();
      else {
        this.props.loadingMessages.alert(
          "Error when updating sick leave form",
          1800
        );
        this.props.loadingMessages.alert("New sick leave created", 1800);
        this.props.loadingMessages.alert(
          "You need to delete the old one",
          1800
        );
      }
    }

    const submitSickLeaveResponse = await database.call("sickLeave/add", {
      userId: this.props.userId,
      createdByUserId: null,
      sicknessStartDate: dayCalculationUtilities.convertDateToStringForSubmission(
        sickLeave.sicknessStartDate
      ),
      sicknessEndDate: dayCalculationUtilities.convertDateToStringForSubmission(
        sickLeave.sicknessEndDate
      ),
      returnToWorkDate: dayCalculationUtilities.convertDateToStringForSubmission(
        sickLeave.returnToWorkDate
      ),
      sicknessReasons: sickLeave.sicknessReasons,
      isInjuryAtWork: sickLeave.isInjuryAtWork,
      hasCompletedAccidentBook: sickLeave.hasCompletedAccidentBook,
      hasReceivedReturnToWorkMeeting: sickLeave.hasReceivedReturnToWorkMeeting,
      token: this.props.token
    });

    let days = await database.call("myHolidays/holidayDaysBetweenDates", {
      startDate: sickLeave.sicknessStartDate,
      endDate: sickLeave.sicknessEndDate,
      token: this.props.token
    });

    sickLeave.days = days.data;

    sickLeave.id = submitSickLeaveResponse.data.sickLeaveId;
    if (submitSickLeaveResponse.data.sickLeaveId) {
      this.reloadSickLeavesFromDatabase();
      this.props.updateSidebar();
      this.toggleForm();
    } else
      this.props.loadingMessages.alert(
        "Problem occurred while saving sick leave",
        3000
      );

    this.props.loadingMessages.removeMessage(savingMessage);
    this.props.loadingMessages.alert("Sick leave saved", 1800);
  };

  toggleFormSelector = async () => {
    await this.setState({ isShowFormSelector: !this.state.isShowFormSelector });
  };

  toggleForm = async () => {
    await this.setState({
      isShowForm: !this.state.isShowForm,
      isEditingSickLeave: false,
      isStillOnSickLeave: false
    });
  };

  render() {
    if (this.state.isLoading) return <div>Loading</div>;
    else {
      return (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            paddingLeft: "25px",
            width: "100%"
          }}
        >
          <PageHeading>
            {!this.state.isShowForm && "My Sick Leave"}
            {this.state.isShowForm &&
              !this.state.isEditingSickLeave &&
              !this.state.isStillOnSickLeave &&
              "Add Sick Leave"}
            {this.state.isStillOnSickLeave && "Add Sick Leave Notification"}
            {this.state.isEditingSickLeave && "Edit Sick Leave"}
          </PageHeading>

          {!this.state.isShowForm && !this.state.isShowFormSelector && (
            <SmallButton onClick={this.toggleFormSelector}>
              Add Sick Leave
            </SmallButton>
          )}

          {this.state.isShowFormSelector && (
            <SickLeaveFormSelector
              showCurrentSickLeaveForm={this.showCurrentSickLeaveForm}
              showCompleteSickLeaveForm={this.showCompleteSickLeaveForm}
              toggleFormSelector={this.toggleFormSelector}
              currentYear={this.props.currentYear}
            />
          )}

          {this.state.isShowForm && (
            <SickLeaveForm
              isEditingSickLeave={this.state.isEditingSickLeave}
              currentSickLeaveData={this.state.currentSickLeaveData}
              submitSickLeave={this.submitSickLeave}
              fixedHolidayDays={this.state.fixedHolidayDays}
              sickLeaves={this.state.sickLeaves}
              loadingMessages={this.props.loadingMessages}
              currentYear={this.props.currentYear}
              isStillOnSickLeave={this.state.isStillOnSickLeave}
              toggleForm={this.toggleForm}
            />
          )}

          {this.props.isOverlapBetweenHolidayAndSickLeave && (
            <div style={{ marginTop: "10px" }}>
              Note: You have got an overlap between a holiday request and sick
              leave.
            </div>
          )}

          {!this.state.isShowForm &&
            !this.state.isShowFormSelector &&
            (this.state.sickLeaves.length < 1 ? (
              <div>
                <hr />
                <p>You do not have any sickness forms</p>
              </div>
            ) : (
              <TableContainer>
                <Table>
                  <Header>
                    <tr>
                      <HeadCell>Sick leave dates</HeadCell>
                      <HeadCell>Days</HeadCell>
                      <HeadCell>Details</HeadCell>
                      <HeadCell>Status</HeadCell>
                      <HeadCell style={{ width: "130px" }} />
                    </tr>
                  </Header>
                  <tbody>
                    {this.state.sickLeaves.map((request, index) => {
                      return (
                        <Row key={index} style={{ cursor: "pointer" }}>
                          <Cell>
                            <SickLeaveFormDatesLabel
                              sicknessStartDate={request.sicknessStartDate}
                              sicknessEndDate={request.sicknessEndDate}
                            />
                          </Cell>
                          <Cell>
                            {request.sicknessEndDate !== undefined &&
                              request.sicknessEndDate !== null &&
                              request.days}
                          </Cell>
                          <Cell>
                            <SickLeaveFormInformationLabel
                              sicknessReasons={request.sicknessReasons}
                              isInjuryAtWork={request.isInjuryAtWork}
                              hasCompletedAccidentBook={
                                request.hasCompletedAccidentBook
                              }
                              hasReceivedReturnToWorkMeeting={
                                request.hasReceivedReturnToWorkMeeting
                              }
                              returnToWorkDate={request.returnToWorkDate}
                              sicknessEndDate={request.sicknessEndDate}
                            />
                          </Cell>
                          <Cell>
                            <SickLeaveFormStatusLabel
                              statusCode={request.status}
                            />
                          </Cell>
                          <Cell>
                            {request.status !== "Acknowledged" && (
                              <button
                                onClick={() => this.deleteSickLeave(request.id)}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "10px"
                                }}
                              >
                                Remove
                              </button>
                            )}
                            {(request.sicknessEndDate === undefined ||
                              request.sicknessEndDate === null) && (
                              <button
                                onClick={() => {
                                  this.props.loadingMessages.alert(
                                    "Editing your sick leave form",
                                    1800
                                  );
                                  this.editSickLeave(request);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Edit
                              </button>
                            )}
                          </Cell>
                        </Row>
                      );
                    })}
                  </tbody>
                </Table>
              </TableContainer>
            ))}
        </div>
      );
    }
  }
}

export default MySickLeaves;
