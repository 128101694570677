import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

import { theme } from "../../constants";

const anim = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1
  }
  100% {
    transform: scale(0.6);
    opacity: 0;
  }
`;

const LoadingWidgetLeft = styled.div`
  margin: 3px;
  height: 6px;
  width: 6px;
  border-radius:6px;
  background-color: white;
  animation: ${anim} 1s ease infinite;
}
`;

const LoadingWidgetMiddle = styled(LoadingWidgetLeft)`
  animation-delay: 0.33s;
`;

const LoadingWidgetRight = styled(LoadingWidgetLeft)`
  animation-delay: 0.66s;
`;

const TopScreenMessage = styled.div`
  position: fixed;
  padding: 10px;
  margin: 0px;
  top: -8px;
  z-index: 100;
  color: white;
  text-align: center;
  left: 28%;
  right: 28%;
  min-height: 24px;
  display:flex;
  justify-content:center;
  align-items: center;
  background-color: ${theme.colors.loadingMessagesBackground};
  flex-direction: column;
`;

class Alert extends Component {

  render() {
    return (
      <TopScreenMessage>
        {!this.props.isShowingAlert &&
          <div style={{display: "flex", justifyContent: "spaceBetween"}}>
            <LoadingWidgetLeft/> <LoadingWidgetMiddle/> <LoadingWidgetRight/>
          </div>
        }
        {this.props.messages.map(message => {
          return (
            <div key={message + Math.random()}>
              {message}
            </div>
          );
        })}
      </TopScreenMessage>
    );
  }
}

export default Alert;
