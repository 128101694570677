import React, { Component } from "react";

import { confirmAlert } from "react-confirm-alert";
import "../../css/pwg-react-confirm-alert.css";

import { database } from "../shared/database";
import {
  dayCalculationUtilities,
  returnOrderedArrayOfDates
} from "../shared/logic";
import StatusLabel from "../shared/statusLabel";
import {
  Cell,
  HeadCell,
  Header,
  PageHeading,
  Row,
  SmallButton,
  Table,
  TableContainer
} from "../shared/styledComponents";

class MyHoliday extends Component {
  state = {
    isLoading: true,
    userId: this.props.userId,
    isShowHolidayRequestForm: false,
    token: this.props.token,
    holidayRequests: [],
    fixedHolidayDays: [],
    datesInAscendingOrder: []
  };

  componentDidMount = () => {
    this.getHolidayRequestsData();
  };

  componentDidUpdate = prevProps => {
    if (this.props.currentYear !== prevProps.currentYear) {
      this.getHolidayRequestsData();
    }
  };

  getHolidayRequestsData = async () => {
    this.props.loadingMessages.addMessage("Loading");

    const fixedHolidayDays = await database.call(
      "myHolidays/fixedHolidayDays",
      {
        year: this.props.currentYear,
        token: this.props.token
      }
    );

    const holidayRequests = await this.getHolidayRequestsFromDatabase();

    await this.setState(
      {
        isLoading: false,
        holidayRequests: holidayRequests.data,
        fixedHolidayDays: fixedHolidayDays.data
      }
    );

    await this.combineHolidayRequestsAndFixedHolidayDays();

    this.props.loadingMessages.removeMessage("Loading");

    if (this.props.isAddingLeaveRequest === true)
      await this.setState({ isShowHolidayRequestForm: true });
  };

  combineHolidayRequestsAndFixedHolidayDays = async () => {
    let allHolidays = [];

    if (this.state.holidayRequests.length > 0)
      allHolidays.push(...this.state.holidayRequests);

    if (this.state.fixedHolidayDays.length > 0)
      allHolidays.push(...this.state.fixedHolidayDays);

    const datesInAscendingOrder = returnOrderedArrayOfDates(allHolidays);
    await this.setState({ datesInAscendingOrder: datesInAscendingOrder });

    this.props.updateSidebar();
  };

  deleteHoliday = holidayRequestId => {
    confirmAlert({
      message: "Are you sure you want to delete the holiday request?",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            this.deleteHolidayFromDatabase(holidayRequestId);
          }
        },
        {
          label: "Cancel"
        }
      ]
    });
  };

  deleteHolidayFromDatabase = async holidayRequestId => {
    this.props.loadingMessages.addMessage("Deleting leave request");

    const deleteHolidayRequestResponse = await database.call(
      "myHolidays/holidayRequest/delete",
      {
        holidayRequestId: holidayRequestId,
        token: this.props.token
      }
    );

    if (
      deleteHolidayRequestResponse &&
      deleteHolidayRequestResponse.data.ROW_COUNT === 1
    )
      this.reloadHolidayRequestsFromDatabase();
    this.props.loadingMessages.removeMessage("Deleting leave request");
  };

  getHolidayRequestsFromDatabase = async () => {

    const holidayRequests = await database.call(
      "myHolidays/holidayRequest/getAllByUser",
      {
        userId: this.props.userId,
        token: this.props.token,
        year: this.props.currentYear
      }
    );

    return holidayRequests;
  };

  reloadHolidayRequestsFromDatabase = async () => {
    const holidayRequests = await this.getHolidayRequestsFromDatabase();
    await this.setState(
      {
        holidayRequests: holidayRequests.data
      },
      this.combineHolidayRequestsAndFixedHolidayDays
    );
  };

  onAddLeaveRequestButton = () => {
    this.props.history.push("/myHoliday/holidayRequestsForm");
  };

  render() {
    if (this.state.isLoading) return <p>Loading</p>;
    else {
      return (
        <div
          data-testid="loadedComponent"
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            paddingLeft: "25px",
            width: "100%"
          }}
        >
          <PageHeading>My {this.props.currentYear} Leave</PageHeading>
          {this.props.remainingHolidayDays <= 0 && (
            <p>
              You have used all of your holiday days for{" "}
              {this.props.currentYear}
            </p>
          )}
          <SmallButton onClick={this.onAddLeaveRequestButton}>
            Add Leave Request
          </SmallButton>

          {this.props.isOverlapBetweenHolidayAndSickLeave && (
            <div style={{ marginTop: "10px" }}>
              Note: You have got an overlap between a holiday request and sick
              leave.
            </div>
          )}

          {!this.state.isShowHolidayRequestForm &&
              <div>
                <TableContainer>
                  {this.state.holidayRequests.length < 1 && (
                    <div>
                      <p data-testid="noHolidayRequestsMessage">
                        You have not requested any holiday leave.
                      </p>
                    </div>
                  )}
                  <Table>
                    <Header>
                      <tr onClick={this.sortDates}>
                        <HeadCell>From</HeadCell>
                        <HeadCell>To</HeadCell>
                        <HeadCell>Days</HeadCell>
                        <HeadCell>Status</HeadCell>
                        <HeadCell>Details</HeadCell>
                        <HeadCell />
                      </tr>
                    </Header>
                    <tbody>
                      {this.state.datesInAscendingOrder.map(
                        (request, index) => {
                          return (
                            <Row key={index} style={{ cursor: "pointer" }}>
                              <Cell>
                                {dayCalculationUtilities.getFormattedDate(
                                  request.startDate
                                    ? request.startDate
                                    : request.date
                                )}
                              </Cell>
                              <Cell>
                                {dayCalculationUtilities.getFormattedDate(
                                  request.endDate
                                    ? request.endDate
                                    : request.date
                                )}
                              </Cell>
                              <Cell>
                                {request.holidayDayType ? (
                                  request.holidayDayType === "All day" ? (
                                    <span>{request.days}</span>
                                  ) : (
                                    <span> 0.5 ({request.holidayDayType})</span>
                                  )
                                ) : (
                                  1
                                )}
                              </Cell>
                              <Cell>
                                <StatusLabel
                                  isAddedByAdmin={
                                    request.userId !== request.createdByUserId
                                  }
                                  createdByUserName={request.createdByUserName}
                                  statusCode={
                                    request.status
                                      ? request.status
                                      : request.name
                                  }
                                />
                              </Cell>
                              <Cell>
                                {request.description &&
                                  `Notes: ${request.description}`}
                                  {request.rejectionReason && <p>
                                    `Rejection: ${request.rejectionReason}`</p>
                                  }

                              </Cell>
                              <Cell>
                                {request.startDate &&
                                  request.status !== "authorized" && (
                                    <button
                                      onClick={() =>
                                        this.deleteHoliday(request.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                      data-testid={
                                        "removeHolidayButton" + index
                                      }
                                    >
                                      Remove
                                    </button>
                                  )}
                                {!request.startDate &&
                                  (request.isPublicHoliday
                                    ? "Public Holiday"
                                    : "Mandatory")}
                              </Cell>
                            </Row>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </TableContainer>
              </div>
          }
        </div>
      );
    }
  }
}

export default MyHoliday;
