import React, { Component } from "react";
import {dayCalculationUtilities} from "./logic";
import moment from "moment";

class SickLeaveFormInformationLabel extends Component {

  getInjuryAtWorkDetails = () => {
    if (!this.props.isInjuryAtWork)
      return 'Not a work injury';
    if (this.props.isInjuryAtWork)
    {
      if (this.props.hasCompletedAccidentBook)
        return 'Work injury (accident book completed)';
      else
        return 'Work injury (accident book not completed)';
    }
  };

  render() {
    if (this.props.sicknessEndDate === undefined || this.props.sicknessEndDate === null)
    {
      return (
        <span style={{fontSize:"0.9em"}}>
            {this.props.sicknessReasons === "" ? "(No details)" : this.props.sicknessReasons}
        </span>
      )
    }
    else {
      return (
        <span style={{fontSize:"0.9em"}}>
          {this.props.sicknessReasons !== "" &&
            <span>
              {this.props.sicknessReasons}<br />
            </span>
          }
          {this.getInjuryAtWorkDetails()}<br />
          {this.props.hasReceivedReturnToWorkMeeting
            ?
            <span>Return to work meeting done</span>
            :
            <span>No return to work meeting</span>
          }
          {
            (this.props.returnToWorkDate === null || this.props.returnToWorkDate === undefined)
              ?
              ""
              :
              <div style={{marginBottom:"7px"}}>
                Returned to work on {dayCalculationUtilities.getFormattedDate(moment(this.props.returnToWorkDate))}
              </div>
          }
        </span>
      );
    }
  }
}

export default SickLeaveFormInformationLabel;
