import React, { Component } from "react";

import { database } from "../shared/database";
import { PageHeading } from "../shared/styledComponents";
import AdminStaffTable from "./adminStaffTable";

class AdminStaff extends Component {
  state = {
    isLoading: true,
    users: []
  };

  componentDidMount = () => {
    this.props.loadingMessages.addMessage("Loading user information");
    this.loadUsers();
    this.props.loadingMessages.removeMessage("Loading user information");
    this.props.updateSidebar();
  };

  componentDidUpdate = prevProps => {
    if (this.props.currentYear !== prevProps.currentYear) {
      this.loadUsers();
    }
  };

  editPerson = async id => {
    this.props.history.push("/adminEditStaff/" + id);
  };

  loadUsers = async () => {
    this.props.loadingMessages.addMessage("Loading users");

    console.log("loadUsers");
    const getUsersResponse = await database.call("helpers/users", {
      year: this.props.currentYear,
      token: this.props.token
    });
    console.log(getUsersResponse);

    await this.setState({
      isLoading: false,
      users: getUsersResponse.data
    });
    this.props.loadingMessages.removeMessage("Loading users");
  };

  render() {
    return (
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          width: "100%"
        }}
      >
        <PageHeading>All Staff</PageHeading>
        <AdminStaffTable
          data={this.state.users}
          isLoading={this.state.isLoading}
          mandatoryHolidayDays={this.props.mandatoryHolidayDays}
          publicHolidayDays={this.props.publicHolidayDays}
          editPerson={this.editPerson}
          currentYear={this.props.currentYear}
        />
      </div>
    );
  }
}

export default AdminStaff;
