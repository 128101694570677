import React, { Component } from "react";

import moment from "moment";
import { confirmAlert } from "react-confirm-alert";

import { database } from "../shared/database";
import {
  dayCalculationUtilities,
  returnOrderedArrayOfDates
} from "../shared/logic";
import { datepickerFormat } from "../../constants";

import {
  SmallButton,
  Table,
  Header,
  HeadCell,
  Cell,
  Row,
  TableContainer,
  PageHeading
} from "../shared/styledComponents";

import FixedHolidayDaysForm from "./fixedHolidayDaysForm";

class FixedHolidayDays extends Component {
  state = {
    isLoading: true,
    fixedHolidayDays: [],
    isShowFixedHolidayDaysForm: false
  };

  componentDidMount = async () => {
    await this.props.loadingMessages.addMessage("Loading fixed holiday days");
    await this.loadFixedHolidayDays();
    await this.props.loadingMessages.removeMessage(
      "Loading fixed holiday days"
    );
    this.props.updateSidebar();
  };

  componentDidUpdate = prevProps => {
    if (this.props.currentYear !== prevProps.currentYear) {
      this.loadFixedHolidayDays();
    }
  };

  loadFixedHolidayDays = async () => {
    const fixedHolidayDays = await database.call(
      "myHolidays/fixedHolidayDays",
      { year: this.props.currentYear, token: this.props.token }
    );

    await this.setState({
      fixedHolidayDays: fixedHolidayDays.data,
      isLoading: false
    });
  };

  toggleFixedHolidayDaysForm = async () => {
    await this.setState({
      isShowFixedHolidayDaysForm: !this.state.isShowFixedHolidayDaysForm
    });
  };

  submitNewFixedHolidayDay = async newFixedHolidayDay => {
    this.props.loadingMessages.addMessage("Saving fixed holiday day");

    const submitFixedHolidayResponse = await database.call(
      "myHolidays/fixedHolidayDay/add",
      {
        userId: this.props.userId,
        name: newFixedHolidayDay.name,
        date: dayCalculationUtilities.convertDateToStringForSubmission(
          newFixedHolidayDay.fixedHolidayDate
        ),
        isPublicHoliday: newFixedHolidayDay.isPublicHoliday,
        token: this.props.token
      }
    );

    this.loadFixedHolidayDays();
    this.props.updateSidebar();

    await this.setState({ isShowFixedHolidayDaysForm: false });

    this.props.loadingMessages.removeMessage("Saving fixed holiday day");

    if (!submitFixedHolidayResponse.data.fixedHolidayDayId)
      this.props.loadingMessages.alert(
        "Sorry a problem occurred when saving day",
        2000
      );
  };

  deleteFixedHoliday = async fixedHolidayDayId => {
    confirmAlert({
      message:
        "Are you sure you want to permanently delete this fixed holiday day? You cannot undo this action.",
      buttons: [
        {
          label: "OK",
          onClick: async () => {
            this.props.loadingMessages.addMessage("Removing fixed holiday day");

            const deleteFixedHolidayResponse = await database.call(
              "myHolidays/fixedHolidayDay/delete",
              {
                fixedHolidayDayId: fixedHolidayDayId,
                token: this.props.token
              }
            );

            this.loadFixedHolidayDays();
            this.props.loadingMessages.removeMessage(
              "Removing fixed holiday day"
            );
            if (deleteFixedHolidayResponse.data.ROW_COUNT === 1)
              this.props.updateSidebar();
            else
              this.props.loadingMessages.alert(
                "Sorry a problem occurred when deleting day"
              );
          }
        },
        {
          label: "Cancel"
        }
      ]
    });
  };

  render() {
    let sortedFixedHolidayDays = returnOrderedArrayOfDates(
      this.state.fixedHolidayDays
    );
    if (this.state.isLoading) {
      return <p>Loading...</p>;
    }
    return (
      <div style={{ marginBottom: "15px", width: "100%" }}>
        <div style={{ marginTop: "10px" }}>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <PageHeading>Fixed Holiday Days</PageHeading>
          </div>
        </div>
        {this.props.isAdmin && (
          <SmallButton onClick={this.toggleFixedHolidayDaysForm}>
            {this.state.isShowFixedHolidayDaysForm
              ? "Cancel"
              : "Add Fixed Holiday Day"}
          </SmallButton>
        )}
        {this.state.isShowFixedHolidayDaysForm && (
          <FixedHolidayDaysForm
            submitNewFixedHolidayDay={this.submitNewFixedHolidayDay}
            currentYear={this.props.currentYear}
            userId={this.props.userId}
            loadingMessages={this.props.loadingMessages}
            fixedHolidayDays={dayCalculationUtilities.convertDateStringArrayIntoMomentArray(
              this.state.fixedHolidayDays
            )}
          />
        )}
        <TableContainer width="100%">
          <Table>
            <Header>
              <tr>
                <HeadCell>Name</HeadCell>
                <HeadCell>Date</HeadCell>
                <HeadCell>Type</HeadCell>
                {this.props.isAdmin && <HeadCell>Delete</HeadCell>}
              </tr>
            </Header>
            <tbody>
              {sortedFixedHolidayDays.map((day, index) => {
                return (
                  <Row key={index} style={{ cursor: "pointer" }}>
                    <Cell>{day.name}</Cell>
                    <Cell>{moment(day.date).format(datepickerFormat)}</Cell>
                    <Cell>
                      {day.isPublicHoliday ? "Public Holiday" : "Mandatory"}
                    </Cell>
                    {this.props.isAdmin && (
                      <Cell>
                        <button onClick={() => this.deleteFixedHoliday(day.id)}>
                          Delete
                        </button>
                      </Cell>
                    )}
                  </Row>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default FixedHolidayDays;
