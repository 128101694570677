import React, { Component } from 'react';
import { withRouter, Switch, Redirect } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { Flex } from 'rebass';
import queryString from 'query-string';

import { database } from './components/shared/database';
import { holidayOverlapCalculator } from './components/shared/holidayOverlapCalculator';
import { pendingRequestsCalculator } from './components/shared/pendingRequestsCalculator';

import PropsRoute from './lib/propsRoute';

import Alert from './components/shared/alert.js';
import LoginForm from './components/shared/loginForm';
import MyHoliday from './components/myHolidays/myHoliday';
import HolidayRequestForm from './components/myHolidays/holidayRequestsForm';
import MySickLeaves from './components/mySickLeave/mySickLeaves';
import PWGCalendar from './components/myHolidays/pwgCalendar';
import StaffHolidayRequests from './components/myTeam/staffHolidayRequests';
import StaffSickLeaves from './components/myTeam/staffSickLeaves';
import TeamOverview from './components/myTeam/teamOverview';
import AdminStaff from './components/holidayAdmin/adminStaff';
import AdminEditStaffForm from './components/holidayAdmin/adminEditStaffForm';
import AdminYears from './components/holidayAdmin/adminYears';
import FixedHolidayDays from './components/fixedHolidayDays/fixedHolidayDays';
import SideMenu from './components/sideMenu/sideMenu';
import Header from './components/header/header';
import UpdateDisplay from './components/shared/updateDisplay';
import UpdateInProgress from './components/shared/updateInProgress';

import { versionData } from './constants';
import versionManager from './components/shared/versionManager';

import { AppContainer, AppContentContainer, Message } from './components/shared/styledComponents';
import { dayCalculationUtilities } from './components/shared/logic';

const TODAYS_DATE = new Date();

class App extends Component {
  state = {
    isCheckingAvailability: true,
    currentVersion: undefined,
    isWorkInProgress: undefined,
    isProduction: undefined,
    currentYear: TODAYS_DATE.getFullYear(),
    calendarDate: undefined,
    departmentId: null,
    calendarFilterState: null,
    entitledHolidayDays: 0,
    isAdmin: false,
    isAuthenticatingSession: false,
    isAuthenticated: false,
    isManager: false,
    isOverlapBetweenHolidayAndSickLeave: false,
    isShowingAlert: false,
    isShowLoginErrorMessage: false,
    isShowUpdateDisplay: false,
    updateDisplayHeading: '',
    updateDisplayVersionNotes: {
      firstPara: '',
      updates: []
    },
    holidayRequests: null,
    mandatoryHolidayDays: 0,
    remainingHolidayDays: null,
    messages: [],
    pendingLeaveRequestCount: 0,
    pendingSickLeaveCount: 0,
    publicHolidayDays: 0,
    token: '',
    usedHolidayDays: 0,
    usedSickDays: 0,
    userFirstName: '',
    userId: -1
  };

  /********************************************************************************/

  componentDidMount = async () => {
    const configuration = await database.call('helpers/configuration/');

    if (configuration && configuration.status !== 200) {
      await this.setState({ isWorkInProgress: true });
    } else if (configuration && configuration.data) {
      await this.setState({
        isProduction: configuration.data.isProduction,
        currentVersion: configuration.data.currentVersion,
        isWorkInProgress: configuration.data.isWorkInProgress,
        isCheckingAvailability: false
      });
    }

    if (this.props.cookies.get('ptpgToken') !== undefined) {
      this.authenticateToken(this.props.cookies.get('ptpgToken'));
    } else {
      const queryStringValues = queryString.parse(this.props.location.search.substr(1));
      if (queryStringValues.key) {
        this.authenticateToken(queryStringValues.key);
      }
    }
    await this.loadingMessages.beginCheckForStuckLoadingMessages();
  };

  /********************************************************************************/

  addLeaveRequest = async day => {
    await this.setState({
      calendarDate: day
    });
  };

  /********************************************************************************/

  authenticate = async formData => {
    this.loadingMessages.addMessage('Authenticating');
    let userPermissionsData = await database.call('helpers/authenticate/', {
      username: formData.pwgUserName,
      password: formData.pwgPassword
    });
    this.loadingMessages.removeMessage('Authenticating');

    if (userPermissionsData.status === 200) {
      this.saveAuthenticationCookie(userPermissionsData.data.token);
      this.updateStateWithUserPermissions(userPermissionsData.data);
    } else this.showTemporaryLoginErrorMessage(2000);
  };

  /********************************************************************************/

  authenticateToken = async token => {
    this.loadingMessages.addMessage('Authenticating your session');
    await this.setState({ isAuthenticatingSession: true });
    let authenticateTokenResponse = await database.call('helpers/authenticateToken', {
      token: token
    });

    this.loadingMessages.removeMessage('Authenticating your session');

    if (authenticateTokenResponse.data.userId) {
      this.saveAuthenticationCookie(token);
      this.updateStateWithUserPermissions(authenticateTokenResponse.data);
    } else this.showTemporaryLoginErrorMessage(2000);

    await this.setState({ isAuthenticatingSession: false });
  };

  /********************************************************************************/

  cancelAddLeaveRequest = async () => {
    await this.setState({
      calendarDate: undefined
    });
  };

  /********************************************************************************/

  hideUpdateDisplay = async () => {
    await this.setState({
      isShowUpdateDisplay: false
    });
  };

  /********************************************************************************/

  loadingMessages = {
    addMessage: message => {
      let amendedMessages = this.state.messages;
      amendedMessages.push(message);
      this.setState({ messages: amendedMessages });
    },
    removeMessage: message => {
      let amendedMessages = this.state.messages;
      amendedMessages.splice(amendedMessages.indexOf(message), 1);
      const removeMessageFromState = () => {
        this.setState({ messages: amendedMessages });
      };
      setTimeout(removeMessageFromState, 1000);
    },
    alert: (message, durationMilliseconds) => {
      this.setState({ isShowingAlert: true });
      this.loadingMessages.addMessage(message);
      setTimeout(() => {
        this.loadingMessages.removeMessage(message);
        this.setState({ isShowingAlert: false });
      }, durationMilliseconds);
    },
    beginCheckForStuckLoadingMessages: () => {
      const errorMessage = 'Problem with your connection';
      let messageBuffer = [];
      const tickLengthMilliseconds = 100;

      const isChangesDetected = (buffer, tickCount) => {
        if (buffer.length < tickCount) return true;
        let lastTick = buffer[buffer.length - 1];
        for (let i = buffer.length - tickCount; i < buffer.length; i++) {
          if (lastTick !== buffer[i]) return true;
        }
        return false;
      };

      setInterval(() => {
        const currentMessages = this.state.messages;
        let messageLength = 0;
        for (let i = 0; i < currentMessages.length; i++) {
          messageLength += currentMessages[i].length;
        }
        messageBuffer.push(messageLength);

        if (this.state.messages.indexOf(errorMessage) !== -1) {
          if (messageLength === errorMessage.length) {
            setTimeout(() => {
              this.loadingMessages.removeMessage(errorMessage);
            }, 500);
          }
        }

        if (!isChangesDetected(messageBuffer, 60)) {
          if (messageLength !== 0 && this.state.messages.indexOf(errorMessage) === -1)
            this.loadingMessages.addMessage(errorMessage);
        }

        if (messageBuffer.length > 300) messageBuffer = [];
      }, tickLengthMilliseconds);
    }
  };

  /********************************************************************************/

  logout = async () => {
    // @Cleanup: this does not log the user out of the current system!!, it only logs them out of the leave manager app
    // @Cleanup: for email/password logins, there is a logout endpoint on the auth server which will log them out of every system.
    this.props.history.push('/');
    this.props.cookies.remove('ptpgToken');
    await this.setState({
      isAuthenticated: false,
      isShowLoginErrorMessage: false
    });
  };

  /********************************************************************************/

  saveAuthenticationCookie = token => {
    this.props.cookies.set('ptpgToken', token, {
      path: '/',
      maxAge: 1800 // 1800 seconds = 30 minutes expiry
    });
  };

  /********************************************************************************/

  setCurrentYear = async year => {
    await this.setState({
      currentYear: year
    });
    this.updateSummaryTotals();
  };

  /********************************************************************************/

  showAllVersionUpdates = async () => {
    const allVersionNotes = versionManager.getAllVersionUpdateNotes(versionData.versionNotes);

    await this.setState({
      isShowUpdateDisplay: true,
      updateDisplayHeading: 'Leave App Update History',
      updateDisplayVersionNotes: {
        firstPara: 'List of all update notes since first release',
        updates: allVersionNotes
      }
    });
  };

  /********************************************************************************/

  showTemporaryLoginErrorMessage = async durationMilliseconds => {
    await this.setState({ isShowLoginErrorMessage: true, isLoading: false }, () => {
      setTimeout(() => {
        this.setState({ isShowLoginErrorMessage: false });
      }, durationMilliseconds);
    });
  };

  /********************************************************************************/

  showVersionUpdate = async lastVersionUsed => {
    const heading = versionManager.getHeading(
      lastVersionUsed,
      versionData.currentVersion,
      versionData.firstUseHeading
    );

    const versionNotes = versionManager.getVersionNotes(
      lastVersionUsed,
      versionData.currentVersion,
      versionData.firstUseText,
      versionData.versionNotes
    );

    await this.setState({
      isShowUpdateDisplay: true,
      updateDisplayHeading: heading,
      updateDisplayVersionNotes: versionNotes
    });
  };

  /********************************************************************************/

  updateSummaryTotals = async () => {
    this.loadingMessages.addMessage('Recalculating totals');

    const { token, userId, currentYear } = this.state;

    // @Cleanup: Could group these requests into one request to the middleware which would authenticate the token once instead of 4 times
    const userData = await database.call('helpers/getUser/', {
      userId,
      token,
      year: currentYear
    });

    const holidayRequests = await database.call('myHolidays/holidayRequest/getAllByUser', {
      userId,
      token,
      year: currentYear
    });

    const sickLeaves = await database.call('sickLeave/getAllByUser', {
      userId,
      token,
      year: currentYear
    });

    const fixedHolidayDays = await database.call('myHolidays/fixedHolidayDays', {
      year: currentYear,
      token
    });

    let entitledHolidayDays = null;
    if (userData && userData.data && userData.data.entitledHolidayDays) {
      entitledHolidayDays = userData.data.entitledHolidayDays;
    }

    const usedHolidayDays = dayCalculationUtilities.getNumberOfLeaveDays(holidayRequests.data);
    const usedSickDays = dayCalculationUtilities.getNumberOfLeaveDays(sickLeaves.data);
    const mandatoryHolidayDays = dayCalculationUtilities.getNumberOfMandatoryHolidayDays(
      fixedHolidayDays.data
    );

    const isOverlapBetweenHolidayAndSickLeave = holidayOverlapCalculator(
      holidayRequests.data,
      sickLeaves.data
    );

    await this.setState({
      entitledHolidayDays,
      holidayRequests: holidayRequests.data,
      usedHolidayDays,
      usedSickDays,
      mandatoryHolidayDays,
      isOverlapBetweenHolidayAndSickLeave,
      publicHolidayDays: fixedHolidayDays.data.length - mandatoryHolidayDays,
      remainingHolidayDays: entitledHolidayDays - usedHolidayDays - mandatoryHolidayDays
    });

    if (this.state.isManager) {
      const teamHolidayRequests = await database.call('myHolidays/holidayRequest/getAllByManager', {
        managerId: userId,
        token,
        year: currentYear
      });
      const teamSickLeaves = await database.call('sickLeave/getAllByManager', {
        managerId: userId,
        token,
        year: currentYear
      });
      const pendingRequestTotals = pendingRequestsCalculator(
        teamHolidayRequests.data,
        teamSickLeaves.data
      );
      await this.setState({
        pendingLeaveRequestCount: pendingRequestTotals.pendingLeaveRequestCount,
        pendingSickLeaveCount: pendingRequestTotals.pendingSickLeaveCount
      });
    }

    this.loadingMessages.removeMessage('Recalculating totals');
  };

  /********************************************************************************/

  updateStateWithUserPermissions = async userPermissionsData => {
    let newCalendarFilterState = {
      isShowMyLeaveOnly: false,
      isShowMyDepartmentOnly: true,
      displayedDepartments: []
    };

    if (userPermissionsData.calendarFilterState === null)
      newCalendarFilterState.displayedDepartments.push(userPermissionsData.departmentId);
    else newCalendarFilterState = JSON.parse(userPermissionsData.calendarFilterState);

    await this.setState(
      {
        isLoading: false,
        isAuthenticated: true,
        userId: userPermissionsData.userId,
        userFirstName: userPermissionsData.firstName,
        isManager: userPermissionsData.isManager === 1,
        isAdmin: userPermissionsData.isAdmin === 1,
        entitledHolidayDays: userPermissionsData.entitledHolidayDays,
        departmentId: userPermissionsData.departmentId,
        calendarFilterState: newCalendarFilterState,
        token: userPermissionsData.token
      },
      this.updateSummaryTotals
    );

    if (
      versionManager.isVersionNewerThanUserVersion(
        versionData.currentVersion,
        userPermissionsData.lastVersionUsed
      )
    )
      this.showVersionUpdate(userPermissionsData.lastVersionUsed);

    await database.call('myHolidays/setVersion', {
      userId: this.state.userId,
      lastVersionUsed: versionData.currentVersion,
      token: this.state.token
    });
  };

  /********************************************************************************/

  render() {
    const {
      isCheckingAvailability,
      isShowingAlert,
      isShowUpdateDisplay,
      isWorkInProgress,
      messages,
      token
    } = this.state;

    if (isCheckingAvailability) return <p />;

    if (isWorkInProgress) return <UpdateInProgress />;

    return (
      <AppContainer>
        {messages.length > 0 && <Alert messages={messages} isShowingAlert={isShowingAlert} />}
        {isShowUpdateDisplay && (
          <UpdateDisplay
            updateDisplayHeading={this.state.updateDisplayHeading}
            updateDisplayVersionNotes={this.state.updateDisplayVersionNotes}
            hideUpdateDisplay={this.hideUpdateDisplay}
          />
        )}
        <Header
          logout={this.logout}
          isAuthenticated={this.state.isAuthenticated}
          userFirstName={this.state.userFirstName}
          isManager={this.state.isManager}
          isAdmin={this.state.isAdmin}
          userId={this.state.userId}
          currentYear={this.state.currentYear}
          mandatoryHolidayDays={this.state.mandatoryHolidayDays}
          entitledHolidayDays={this.state.entitledHolidayDays}
          usedHolidayDays={this.state.usedHolidayDays}
          remainingHolidayDays={this.state.remainingHolidayDays}
          usedSickDays={this.state.usedSickDays}
          token={token}
          currentVersion={versionData.currentVersion}
          showAllVersionUpdates={this.showAllVersionUpdates}
          isProduction={this.state.isProduction}
        />
        {this.state.isAuthenticated ? (
          <AppContentContainer>
            <Flex>
              <PropsRoute
                path="/"
                component={SideMenu}
                userFirstName={this.state.userFirstName}
                pendingLeaveRequestCount={this.state.pendingLeaveRequestCount}
                pendingSickLeaveCount={this.state.pendingSickLeaveCount}
                isManager={this.state.isManager}
                isAdmin={this.state.isAdmin}
                entitledHolidayDays={this.state.entitledHolidayDays}
                mandatoryHolidayDays={this.state.mandatoryHolidayDays}
                usedHolidayDays={this.state.usedHolidayDays}
                remainingHolidayDays={this.state.remainingHolidayDays}
                usedSickDays={this.state.usedSickDays}
                currentYear={this.state.currentYear}
                setCurrentYear={this.setCurrentYear}
              />
              <Switch>
                <PropsRoute
                  path="/"
                  exact
                  component={PWGCalendar}
                  mandatoryHolidayDays={this.state.mandatoryHolidayDays}
                  userFirstName={this.state.userFirstName}
                  userId={this.state.userId}
                  departmentId={this.state.departmentId}
                  calendarFilterState={this.state.calendarFilterState}
                  updateSidebar={this.updateSummaryTotals}
                  currentYear={this.state.currentYear}
                  loadingMessages={this.loadingMessages}
                  token={token}
                  addLeaveRequest={this.addLeaveRequest}
                />
                <PropsRoute
                  path="/myHoliday"
                  exact
                  component={MyHoliday}
                  userId={this.state.userId}
                  fixedHolidayDays={this.state.fixedHolidayDays}
                  holidayRequests={this.state.holidayRequests}
                  remainingHolidayDays={this.state.remainingHolidayDays}
                  updateSidebar={this.updateSummaryTotals}
                  isOverlapBetweenHolidayAndSickLeave={
                    this.state.isOverlapBetweenHolidayAndSickLeave
                  }
                  currentYear={this.state.currentYear}
                  loadingMessages={this.loadingMessages}
                  token={token}
                  cancelAddLeaveRequest={this.cancelAddLeaveRequest}
                />
                <PropsRoute
                  exact
                  path="/myHoliday/holidayRequestsForm/"
                  component={HolidayRequestForm}
                  currentYear={this.state.currentYear}
                  fixedHolidayDays={this.state.fixedHolidayDays}
                  remainingHolidayDays={this.state.remainingHolidayDays}
                  holidayRequests={this.state.holidayRequests}
                  loadingMessages={this.loadingMessages}
                  submitHolidayRequest={this.state.submitRequestButton}
                  token={token}
                  calendarDate={this.state.calendarDate}
                  cancelAddLeaveRequest={this.cancelAddLeaveRequest}
                  addLeaveRequest={this.addLeaveRequest}
                  userId={this.state.userId}
                />
                <PropsRoute
                  exact
                  path="/myHoliday/holidayRequestsForm/:id"
                  component={HolidayRequestForm}
                  currentYear={this.state.currentYear}
                  fixedHolidayDays={this.state.fixedHolidayDays}
                  remainingHolidayDays={this.state.remainingHolidayDays}
                  holidayRequests={this.state.holidayRequests}
                  loadingMessages={this.loadingMessages}
                  submitHolidayRequest={this.props.submitRequestButton}
                  token={token}
                  calendarDate={this.state.calendarDate}
                  cancelAddLeaveRequest={this.cancelAddLeaveRequest}
                  addLeaveRequest={this.addLeaveRequest}
                  userId={this.state.userId}
                />
                <PropsRoute
                  path="/mySickLeave"
                  exact={true}
                  component={MySickLeaves}
                  userId={this.state.userId}
                  updateSidebar={this.updateSummaryTotals}
                  isOverlapBetweenHolidayAndSickLeave={
                    this.state.isOverlapBetweenHolidayAndSickLeave
                  }
                  currentYear={this.state.currentYear}
                  loadingMessages={this.loadingMessages}
                  token={token}
                />
                <PropsRoute
                  path="/fixedHolidayDays"
                  component={FixedHolidayDays}
                  userId={this.state.userId}
                  isAdmin={this.state.isAdmin}
                  updateSidebar={this.updateSummaryTotals}
                  currentYear={this.state.currentYear}
                  loadingMessages={this.loadingMessages}
                  token={token}
                />
                {this.state.isManager && (
                  <PropsRoute
                    path="/teamOverview"
                    component={TeamOverview}
                    userId={this.state.userId}
                    updateSidebar={this.updateSummaryTotals}
                    loadingMessages={this.loadingMessages}
                    token={token}
                    currentYear={this.state.currentYear}
                    mandatoryHolidayDays={this.state.mandatoryHolidayDays}
                  />
                )}
                {this.state.isManager && (
                  <PropsRoute
                    path="/staffHolidayRequests"
                    component={StaffHolidayRequests}
                    userId={this.state.userId}
                    currentYear={this.state.currentYear}
                    token={token}
                    loadingMessages={this.loadingMessages}
                    updateSidebarPendingActionCounts={this.updateSummaryTotals}
                  />
                )}
                {this.state.isManager && (
                  <PropsRoute
                    path="/staffSickLeave"
                    exact={true}
                    component={StaffSickLeaves}
                    userId={this.state.userId}
                    currentYear={this.state.currentYear}
                    token={token}
                    loadingMessages={this.loadingMessages}
                    updateSidebarPendingActionCounts={this.updateSummaryTotals}
                  />
                )}
                {this.state.isAdmin && (
                  <PropsRoute
                    path="/adminStaff"
                    component={AdminStaff}
                    loadingMessages={this.loadingMessages}
                    updateSidebar={this.updateSummaryTotals}
                    token={token}
                    currentYear={this.state.currentYear}
                    mandatoryHolidayDays={this.state.mandatoryHolidayDays}
                    publicHolidayDays={this.state.publicHolidayDays}
                    updateSummaryTotals={this.updateSummaryTotals}
                  />
                )}
                {this.state.isAdmin && (
                  <PropsRoute
                    path="/adminEditStaff/:id"
                    component={AdminEditStaffForm}
                    loadingMessages={this.loadingMessages}
                    token={token}
                    currentYear={this.state.currentYear}
                    userId={this.state.userId}
                  />
                )}
                {this.state.isAdmin && (
                  <PropsRoute
                    path="/adminYears"
                    component={AdminYears}
                    currentYear={this.state.currentYear}
                    token={token}
                    loadingMessages={this.loadingMessages}
                    updateSidebar={this.updateSummaryTotals}
                  />
                )}
                {/*If requested path is not available, or user does not have permissions, then it will redirect to calendar*/}
                <Redirect to="/" />
              </Switch>
            </Flex>
          </AppContentContainer>
        ) : (
          <AppContentContainer>
            {this.state.isLoading ? (
              <p>Loading...</p>
            ) : this.state.isAuthenticatingSession ? (
              <Message>Please wait - retrieving your user session</Message>
            ) : (
              <LoginForm
                authenticate={this.authenticate}
                isShowLoginErrorMessage={this.state.isShowLoginErrorMessage}
              />
            )}
          </AppContentContainer>
        )}
      </AppContainer>
    );
  }
}

export default withRouter(withCookies(App));
