import React, { Component } from "react";
import styled from 'styled-components';

import { theme } from "../../constants";
import logo from "../../assets/icons/pwg-tree-logo-90-82.png";

const UpdateDisplayContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  z-index: 1000;
  width: 600px;
  min-height: 500px;
  transform: translate(-50%, 0);
  background-color: white;
  border: 1px solid #ababab;
  padding: 30px;
  font-size: 16px;
`;

const UpdateDisplayHr = styled.hr`
  margin-top: 35px;
  margin-bottom: 35px;
  color: ${theme.colors.pwg};
`;

const UpdateDisplayHeader = styled.h3`
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  margin: 25px;
`;

const DismissButton = styled.button`
  margin-top: 30px;
  cursor: pointer;
  padding: 10px;
  width: 150px;
`;

class UpdateDisplay extends Component {
  render() {
    return (
      <UpdateDisplayContainer>
        <div style={{textAlign: "center"}}>
          <img src={logo} alt="PWG logo" />
        </div>
        <UpdateDisplayHeader>
          {this.props.updateDisplayHeading}
        </UpdateDisplayHeader>
        <UpdateDisplayHr />
        <p>
          {this.props.updateDisplayVersionNotes.firstPara}
        </p>
        <ul>
        {this.props.updateDisplayVersionNotes.updates.map(note => (
            <li key={note}>
              {note}
            </li>
          )
        )}
        </ul>
        <div style={{textAlign: "center"}}>
          <DismissButton onClick={this.props.hideUpdateDisplay}>OK</DismissButton>
        </div>
      </UpdateDisplayContainer>
    );
  }
}

export default UpdateDisplay;
