import React from "react";
import moment from "moment";
import {ArrowLeft, ArrowRight} from "styled-icons/fa-solid";

const PWGCalendarMonthTitle = props => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "200px"
      }}
    >
      <div
        onClick={() =>
          props.setCalendarDate(
            moment(props.calendarDate)
              .subtract(1, "months")
              .startOf("month")
              .toDate()
          )
        }
      >
        {moment(props.calendarDate).isAfter(
          moment(props.calendarDate).startOf("year"),
          "month"
        ) && (
          <ArrowLeft
            style={{ color: "#ababab" }}
            size={18}
            title="Previous"
          />
        )}
      </div>
      <div className="rbc-toolbar-label">
        {moment(props.calendarDate).format("MMMM YYYY")}
      </div>
      <div
        onClick={() =>
          props.setCalendarDate(
            moment(props.calendarDate)
              .add(1, "months")
              .startOf("month")
              .toDate()
          )
        }
      >
        {moment(props.calendarDate).isBefore(
          moment(props.calendarDate).endOf("year"),
          "month"
        ) && (
          <ArrowRight style={{ color: "#ababab" }} size={18} title="Next" />
        )}
      </div>
    </div>
  );
};


export default PWGCalendarMonthTitle;
