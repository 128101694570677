export const appVersionTitle = "BETA 0.1.2";

export const versionData = {
  currentVersion: "0.1.2",
  firstUseHeading: "Welcome to the PWG Leave App",
  firstUseText: "This app can be used to submit leave requests and sickness forms to your manager. Managers can use it to review and approve leave requests, and keep track of leave within their team.",
  versionNotes: [
    {
      date: "2019-02-18",
      version: "0.1.0",
      notes: []
    },
    {
      date: "2019-03-08",
      version: "0.1.1",
      notes: [
        "Fixed issue where some leave was crossing over into the weekend on the calendar. Thank you Lisa for spotting this!",
        "Improvements to make calendar easier to read",
        "You can now add leave that overlaps with public holidays",
        "Some improvements to how things work in Internet Explorer - we still recommend Chrome or Firefox though"
      ]
    },
    {
      date: "2019-03-18",
      version: "0.1.2",
      notes: [
        "Added filter on calendar view so you can view just your own department",
        "You can now add an optional description when making a leave request",
        "Managers have to give a reason if rejecting a leave request",
        "Leave requests can now be deleted, approved and rejected directly in the calendar"
      ]
    }
  ]
};

export const headerDateFormat = "dddd Do MMMM YYYY";
export const datepickerFormat = "DD/MM/YYYY";

export const theme = {
  font: "Verdana, Arial, Tahoma, sans-serif",
  colors: {
    error: "#ff0000",
    primary: "#7a8e8d",
    heading: "#5D6797",
    pageHeading: "#c5651f",
    gray2: "#aeaeae",
    border: "#aeaeae",
    darkBorder: "#636a0c",
    toolTipBackground: "#dde6e7",
    pwg: "#858b1f",
    lightGreen: "#e0e4c2",
    dateBackground: "#eff2de",
    dashboardTitle: "#545b21",
    calendarEventPalette: [
      "#ffffff",
      "#fb7c3f",
      "#e0e4c2",
      "#bdd8c3",
      "e7a652"
    ],
    loadingMessagesBackground: "#858b1f",
    form: {
      invalidInputHighlight: "#ff0000"
    },
    table: {
      tableHeader: "#dbe5c5",
      tableCellBorder: "#ccc",
      rowBackgroundColourOdd: "#f6fbe7",
      rowBackgroundColourEven: "#fff",
      rowBackgroundColourHighlight: "#dbe5c5"
    },
    sideMenu: {
      background: "#fffecb"
    },
    header: {
      tabBackgroundLight: "#f2f6e4",
      tabBackground: "#d0d3b1",
      tabBorder: "#d0d3b1"
    },
    leaveCalendar: {
      userLeaveRequest: "#669933", // was '#fb7c3f'
      otherPersonLeaveRequest: "#669933", // was '#858b1f'
      rejectedLeaveRequest: "#ff4444",
      publicHoliday: "#787878", // was '#669933'
      mandatoryHolidayDay: "#858b1f" // was '#669933'
    }
  },
  zIndexes: {
    menu: 999
  }
};
