import React, { Component } from "react";
import moment from "moment";

import { database } from "../shared/database";
import SickLeaveFormDatesLabel from "../shared/sickLeaveFormDatesLabel";
import SickLeaveFormStatusLabel from "../shared/sickLeaveFormStatusLabel";
import RequestActionButton from "../shared/requestActionButton";
import {
  Table,
  Header,
  HeadCell,
  Cell,
  Row,
  TableContainer,
  PageHeading
} from "../shared/styledComponents";
import {
  dayCalculationUtilities,
  returnOrderedArrayOfDates
} from "../shared/logic";
import SickLeaveFormInformationLabel from "../shared/sickLeaveFormInformationLabel";

class StaffSickLeaves extends Component {
  state = {
    isLoading: true,
    sickLeaves: null
  };

  componentDidMount = () => {
    this.getStaffSickLeavesData();
  };

  componentDidUpdate = prevProps => {
    if (this.props.currentYear !== prevProps.currentYear) {
      this.getStaffSickLeavesData();
    }
  };
  getStaffSickLeavesData = async () => {
    this.props.loadingMessages.addMessage("Loading my team's sick leave");

    const sickLeaveRequests = await database.call("sickLeave/getAllByManager", {
      managerId: this.props.userId,
      token: this.props.token,
      year: this.props.currentYear
    });

    this.props.loadingMessages.removeMessage("Loading my team's sick leave");
    const orderedArrayOfSickLeaves = returnOrderedArrayOfDates(
      sickLeaveRequests.data
    );
    await this.setState({
      isLoading: false,
      sickLeaves: orderedArrayOfSickLeaves,
      token: this.props.token
    });
    this.props.updateSidebarPendingActionCounts();
  };

  acknowledge = async sickLeaveId => {
    this.props.loadingMessages.addMessage("Acknowledging sick leave");

    const acknowledgeSickLeaveResponse = await database.call(
      "sickLeave/acknowledge",
      {
        sickLeaveId: sickLeaveId,
        managerId: this.props.userId,
        token: this.props.token
      }
    );

    this.props.loadingMessages.removeMessage("Acknowledging sick leave");
    if (acknowledgeSickLeaveResponse.data.ROW_COUNT === 1)
      this.updateSickLeaveFormInComponentState(sickLeaveId);
    this.props.updateSidebarPendingActionCounts();
  };

  updateSickLeaveFormInComponentState = async sickLeaveId => {
    let updatedData = this.state.sickLeaves;
    for (let i = 0; i < updatedData.length; i++) {
      if (updatedData[i].id === sickLeaveId) {
        updatedData[i].status = "Acknowledged";
      }
    }
    await this.setState({ sickLeaves: updatedData });
  };

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div style={{ paddingBottom: "15px", width: "100%" }}>
        <div style={{ marginTop: "10px" }}>
          <PageHeading>Staff Sick Leaves</PageHeading>
        </div>
        {this.state.sickLeaves.length <= 0 && (
          <span>No sick leave at this time</span>
        )}
        {this.state.sickLeaves.length > 0 && (
          <TableContainer>
            <Table>
              <Header>
                <tr>
                  <HeadCell>Date submitted</HeadCell>
                  <HeadCell>Name</HeadCell>
                  <HeadCell>Sick leave dates</HeadCell>
                  <HeadCell>Days</HeadCell>
                  <HeadCell>Details</HeadCell>
                  <HeadCell>Status</HeadCell>
                </tr>
              </Header>
              <tbody>
                {this.state.sickLeaves.map((request) => {
                  return (
                    <Row key={request.id} style={{ cursor: "pointer" }}>
                      <Cell>
                        {dayCalculationUtilities.getFormattedDate(
                          moment(request.dateSubmitted)
                        )}
                      </Cell>
                      <Cell>{request.name}</Cell>
                      <Cell>
                        <SickLeaveFormDatesLabel
                          sicknessStartDate={request.sicknessStartDate}
                          sicknessEndDate={request.sicknessEndDate}
                        />
                      </Cell>
                      <Cell>
                        {request.sicknessEndDate !== undefined &&
                          request.sicknessEndDate !== null &&
                          request.days}
                      </Cell>
                      <Cell>
                        <SickLeaveFormInformationLabel
                          sicknessReasons={request.sicknessReasons}
                          isInjuryAtWork={request.isInjuryAtWork}
                          hasCompletedAccidentBook={
                            request.hasCompletedAccidentBook
                          }
                          hasReceivedReturnToWorkMeeting={
                            request.hasReceivedReturnToWorkMeeting
                          }
                          returnToWorkDate={request.returnToWorkDate}
                          sicknessEndDate={request.sicknessEndDate}
                        />
                      </Cell>
                      <Cell>
                        <SickLeaveFormStatusLabel
                          statusCode={request.status}
                          returnToWorkDate={request.returnToWorkDate}
                        />
                        {request.status === "Pending" && (
                          <div style={{ marginTop: "5px" }}>
                            <RequestActionButton
                              id={request.id}
                              label="Acknowledge"
                              status="acknowledged"
                              action={this.acknowledge}
                            />
                          </div>
                        )}
                      </Cell>
                    </Row>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  }
}

export default StaffSickLeaves;
