import React, { Component } from "react";
import styled from 'styled-components';

const ActionButton = styled.button`
    margin-bottom: 3px;
    margin-right: 7px;
`;

class RequestActionButton extends Component {
    render() {
        return (
            <ActionButton onClick={() => this.props.action(this.props.id, this.props.status)}>
                {this.props.label}
            </ActionButton>
        );
    }
}

export default RequestActionButton;
