import React, { Component } from "react";
import moment from "moment";
import {Square} from "styled-icons/fa-solid/Square";

import { database } from "../shared/database";
import { dayCalculationUtilities } from "../shared/logic";
// import managerTeamOverlapGraphic from "../../assets/images/manager-team-overlap-graphic.png";
// import managerTeamCumulativeGraphic from "../../assets/images/manager-team-cumulative-graphic.png";

import {
  Cell,
  HeadCell,
  Header,
  Row,
  Table
} from "../shared/styledComponents";

import { PageHeading } from "../shared/styledComponents";
import UserLeaveGraphic from "../shared/userLeaveGraphic";

class TeamOverview extends Component {
  state = {
    users: [],
    workingDaysUntilEndOfYear: [],
    analysisDisplay: "overlap"
  };

  componentDidMount = () => {
    this.loadUsers();
  };

  componentDidUpdate = prevProps => {
    if (this.props.currentYear !== prevProps.currentYear)
      this.loadUsers();
  };

  getRemainingDaysStyle = ratio => {
    if (ratio > 0.5) return { color: "red" };
    else return { color: "#000000" };
  };

  getUsersManagedByUser = (users, userId) => {
    let managedUsers = [];
    for (let i = 0; i < users.length; i++) {
      if (users[i].managerId === userId) {
        const leaveDaysRemaining =
          users[i].entitledHolidayDays - users[i].leaveDaysUsed;
        users[i].leaveRemainingRatio =
          leaveDaysRemaining / this.state.workingDaysUntilEndOfYear.length;
        users[i].leaveDaysRemaining = leaveDaysRemaining;
        managedUsers.push(users[i]);
      }
    }
    return managedUsers;
  };

  loadUsers = async () => {
    this.props.loadingMessages.addMessage("Loading users");
    const getUsersResponse = await database.call("helpers/users", {
      year: this.props.currentYear,
      token: this.props.token
    });

    this.props.loadingMessages.removeMessage("Loading users");

    const today = moment();
    const endOfYear = moment().endOf("year");
    const workingDaysUntilEndOfYear = dayCalculationUtilities.getWorkingDaysBetweenDatesAsArray(
      today,
      endOfYear
    );

    await this.setState({
      workingDaysUntilEndOfYear: workingDaysUntilEndOfYear
    });

    await this.setState({
      users: this.getUsersManagedByUser(
        getUsersResponse.data,
        this.props.userId
      )
    });
  };

  toggleAnalysisDiagram = async () => {
    await this.setState({
      analysisDisplay:
        this.state.analysisDisplay === "overlap" ? "cumulative" : "overlap"
    });
  };

  render() {
    return (
      <div style={{ width: "100%", paddingRight: "15px" }}>
        <PageHeading>My Team Overview</PageHeading>
        <p>
          You can authorize holiday requests and acknowledge sick leave for
          members of your team
        </p>
        {/*<p>
          {this.state.workingDaysUntilEndOfYear.length !== 0 && (
            <span>
              Working days until the end of the current year:{" "}
              {this.state.workingDaysUntilEndOfYear.length}
            </span>
          )}
        </p>
      */}

        <div style={{padding:"20px", border:"1px solid #ccc"}}>
          {this.props.mandatoryHolidayDays !== undefined &&
          this.props.mandatoryHolidayDays !== null && (
            <span>
                <Square size={16} style={{color: "#676767"}} /> - Mandatory leave
                {
                  this.props.mandatoryHolidayDays !== 0
                  &&
                  ` (${this.props.mandatoryHolidayDays} days this year for everyone)`
                }
              </span>
          )
          }
          <br />
          <Square size={16} style={{color: "#669933"}} /> - Leave requests (including pending, authorised and rejected)
          <br />
          <Square size={16} style={{color: "#c5651f"}} /> - Remaining leave
        </div>

        <div style={{marginBottom:"30px", marginTop: "20px"}}>

        {this.state.users.map(user =>
          <div key={user.id} style={{marginTop: "7px", display:"flex", justifyContent:"flex-left", alignItems:"center"}}>
            <div style={{marginRight:"20px", width:"200px"}}>
              {user.firstName} {user.lastName}
            </div>
            <div style={{paddingLeft:"20px"}}>
              {this.state.users !== [] &&
              <UserLeaveGraphic
                mandatoryHolidayDays={this.props.mandatoryHolidayDays}
                entitledHolidayDays={user.entitledHolidayDays}
                leaveDaysUsed={user.leaveDaysUsed}
              />
              }
            </div>
          </div>
        )}

        </div>

        <Table style={{ width: "100%" }}>
          <Header style={{ textAlign: "left" }}>
            <tr>
              <HeadCell>Name</HeadCell>
              <HeadCell>Total</HeadCell>
              <HeadCell>Mandatory<br />Leave</HeadCell>
              <HeadCell>Other Leave<br />requests</HeadCell>
              <HeadCell>Remaining</HeadCell>
              <HeadCell>Sick days</HeadCell>
            </tr>
          </Header>
          <tbody>
            {this.state.users.map(user => {
              return (
                <Row key={user.id}>
                  <Cell>
                    {user.firstName} {user.lastName}
                  </Cell>
                  <Cell>
                    {user.entitledHolidayDays}
                  </Cell>
                  <Cell>
                    {this.props.mandatoryHolidayDays}
                  </Cell>
                  <Cell>
                    {user.leaveDaysUsed} days
                  </Cell>
                  <Cell>
                    {this.state.workingDaysUntilEndOfYear.length !== 0 && (
                      <span
                        style={this.getRemainingDaysStyle(
                          user.leaveRemainingRatio
                        )}
                      >
                        {user.leaveDaysRemaining -
                          this.props.mandatoryHolidayDays}{" "}
                        days
                        {user.leaveRemainingRatio < 1 &&
                          user.leaveRemainingRatio > 0.5 && (
                            <span>
                              &nbsp;out of{" "}
                              {this.state.workingDaysUntilEndOfYear.length}{" "}
                              working days left in year
                              <br />
                              Needs to take over 50% of remaining year as leave!
                            </span>
                          )}
                        {user.leaveRemainingRatio > 1 && (
                          <span>
                            &nbsp;(but only{" "}
                            {this.state.workingDaysUntilEndOfYear.length}{" "}
                            working days left in year)
                            <br />
                            Not enough time to take all leave!
                          </span>
                        )}
                      </span>
                    )}
                  </Cell>
                  <Cell>{user.sickDaysUsed}</Cell>
                </Row>
              );
            })}
          </tbody>
        </Table>
        <br />
        <p>
          {this.state.users.length === 0 && "Loading details for your team"}
        </p>
        {/*
          <hr /><div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          {}<PageHeading>Leave analysis (mock-up - not real data)</PageHeading>
          <Button onClick={() => this.toggleAnalysisDiagram()}>
            {this.state.analysisDisplay === "overlap"
              ? "Show cumulative leave"
              : "Show leave overlap"}
          </Button>
        </div>
        {this.state.analysisDisplay === "overlap" ? (
          <img
            alt="Manager team overlap graphic"
            style={{ width: "100%" }}
            src={managerTeamOverlapGraphic}
          />
        ) : (
          <img
            alt="Manager team overlap graphic"
            style={{ width: "100%" }}
            src={managerTeamCumulativeGraphic}
          />
        )}
      */}
      </div>
    );
  }
}

export default TeamOverview;
