import React, { Component } from "react";
import { Flex, Box } from "rebass";

import { ErrorMessage, LoginFormContainer } from "../shared/styledComponents";
import {
  PageHeading,
  FieldContainer,
  FixedWidthLabel,
  FixedWidthInput
} from "../shared/styledComponents";

class LoginForm extends Component {
  state = {
    pwgUserName: "",
    pwgPassword: ""
  };

  handleInputChange = async event => {
    await this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.authenticate(this.state);
  };

  render() {
    return (
      <div style={{ padding: "25px" }}>
        <PageHeading>Login</PageHeading>
        <hr />
        <p>Please log in using your PWG control centre account</p>
        <div style={{ height: "50px" }}>
          {this.props.isShowLoginErrorMessage && (
            <ErrorMessage>
              Sorry your login details have not been recognized. Please try
              again.
            </ErrorMessage>
          )}
        </div>
        <Flex>
          <LoginFormContainer onSubmit={this.handleSubmit}>
            <Box>
              <FieldContainer>
                <FixedWidthLabel htmlFor="pwgUserName">
                  Username
                </FixedWidthLabel>
                <FixedWidthInput
                  type="text"
                  id="pwgUserName"
                  name={"pwgUserName"}
                  className={"pwgUserName"}
                  value={this.state.pwgUserName}
                  onChange={this.handleInputChange}
                  required
                />
              </FieldContainer>
              <FieldContainer>
                <FixedWidthLabel htmlFor="pwgPassword">
                  Password
                </FixedWidthLabel>
                <FixedWidthInput
                  type="password"
                  id="pwgPassword"
                  name={"pwgPassword"}
                  className={"pwgPassword"}
                  value={this.state.pwgPassword}
                  onChange={this.handleInputChange}
                />
              </FieldContainer>
            </Box>
            <br />
            <Box align="right">
              <button
                style={{
                  marginRight: "198px",
                  padding: "5px 20px 5px 20px",
                  cursor: "pointer"
                }}
                type="submit"
                data-testid="loginButton"
                disabled={
                  this.state.pwgUserName !== "" &&
                  this.state.pwgPassword !== "" ? (
                    false
                  ) : (
                    "disabled"
                  )
                }
              >
                Login
              </button>
            </Box>
          </LoginFormContainer>
        </Flex>
      </div>
    );
  }
}

export default LoginForm;
