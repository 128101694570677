import React, { Component } from "react";
import moment from "moment";

import { HeaderContainer, HeaderTitle } from "../shared/styledComponents";
import HeaderPopUpPanel from "./headerPopUpPanel";
import HeaderTab from "./headerTab";
import {
  AppPermissionsTitle,
  Button,
  DateDisplay,
  HeaderControls,
  HeaderMenuIcon,
  UserDetailsNameLabel
} from "../shared/styledComponents";
import logo from "../../assets/icons/pwg-tree-logo-90-82.png";
import { headerDateFormat, appVersionTitle } from "../../constants";
import { Th } from "styled-icons/fa-solid";
import { User } from "styled-icons/fa-solid/User";

class Header extends Component {
  state = {
    isAppMenuShown: false,
    isUserPanelShown: false
  };

  logout = () => {
    this.toggleUserPanel();
    this.props.logout();
  };

  hidePopups = async e => {
    if (e.target.dataset.action === "logout")
      this.logout();
    if (e.target.dataset.action === "showAllVersionUpdates")
      this.props.showAllVersionUpdates();
    await this.setState({
      isAppMenuShown: false,
      isUserPanelShown: false
    });
  };

  toggleAppMenu = async () => {
    await this.setState({
      isAppMenuShown: !this.state.isAppMenuShown,
      isUserPanelShown: false
    });
  };

  toggleUserPanel = async () => {
    await this.setState({
      isAppMenuShown: false,
      isUserPanelShown: !this.state.isUserPanelShown
    });
  };

  render() {
    return (
      <HeaderContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt={"logo"} />
          <HeaderControls>
            <HeaderTitle>PWG LEAVE MANAGEMENT</HeaderTitle>
            <div style={{marginLeft:"10px", color:"#ff0000"}}>
              {appVersionTitle}
              {this.props.isProduction === 0 && <strong> (DEV)</strong>}
            </div>
            <DateDisplay ml={3} p={1}>
              {moment().format(headerDateFormat)}
            </DateDisplay>
            <HeaderMenuIcon onClick={this.toggleAppMenu}>
              <Th size={16} style={{ marginTop: "7px" }} />
            </HeaderMenuIcon>
            <HeaderMenuIcon onClick={this.toggleUserPanel}>
              {this.props.isAuthenticated ? (
                <User size={16} style={{ marginTop: "7px" }} />
              ) : (
                <User
                  size={16}
                  style={{ marginTop: "7px", color: "#cdcdcd" }}
                />
              )}
            </HeaderMenuIcon>
          </HeaderControls>
        </div>
        {this.state.isAppMenuShown && (
          <HeaderPopUpPanel
            title="PWG Apps"
            isApps={true}
            rightOffset={12}
            hidePopups={this.hidePopups}
          />
        )}
        {this.state.isUserPanelShown && (
          <HeaderPopUpPanel
            title="User Details"
            isApps={false}
            rightOffset={-22}
            hidePopups={this.hidePopups}
          >
            {this.props.isAuthenticated ? (
              <div style={{ width: "100%" }}>
                <UserDetailsNameLabel>
                  {this.props.userFirstName}
                </UserDetailsNameLabel>

                <p>User ID {this.props.userId}</p>

                <AppPermissionsTitle>{this.props.currentYear} Summary</AppPermissionsTitle>
                <p>
                  Leave entitlement: {this.props.entitledHolidayDays}<br />
                  Mandatory holiday days: {this.props.mandatoryHolidayDays}<br />
                  Leave days used: {this.props.usedHolidayDays}<br />
                  Leave remaining: {this.props.remainingHolidayDays}
                </p>
                <p>
                  Sick days: {this.props.usedSickDays}
                </p>
                {(this.props.isManager || this.props.isAdmin) && (
                  <AppPermissionsTitle>App permissions</AppPermissionsTitle>
                )}
                <p>
                  {this.props.isManager && (
                    <span>
                      Manager<br />
                    </span>
                  )}
                  {this.props.isAdmin && (
                    <span>
                      Leave App Administrator<br />
                    </span>
                  )}
                </p>
                <hr />
                <p>
                  PWG Leave App Version {this.props.currentVersion}<br />
                  <span
                    style={{cursor:"pointer", color: "blue"}}
                    data-action="showAllVersionUpdates"
                  >
                    View app update history
                  </span>
                </p>

                <p style={{ textAlign: "right" }}>
                  <Button type="button" data-action="logout">
                    Log out
                  </Button>
                </p>
              </div>
            ) : (
              <p>You are not logged in</p>
            )}
          </HeaderPopUpPanel>
        )}
        <HeaderTab
          rightOffset="-2px"
          label="TW"
          link="https://dashboard.thepwgroup.net/control/control_center.cgi"
        />
        <HeaderTab
          rightOffset="94px"
          label="Dale"
          link="https://dashboard.thepwgroup.net/control/control_center_dl_dashboard.cgi"
        />
        <HeaderTab
          rightOffset="192px"
          label="PWG"
          link="https://dashboard.thepwgroup.net/control/control_center_pwg_dashboard.cgi"
        />
        <HeaderTab rightOffset="290px" label="Leave" isActive />
      </HeaderContainer>
    );
  }
}

export default Header;
