import menuClosedIcon from "./submenuClose.gif";
import menuOpenedIcon from "./submenuOpen.gif";
import helpIcon from "./help_icon.gif";
import smileyFaceIcon from "./smile.gif";

export const icons = {
  menuClosedIcon: menuClosedIcon,
  menuOpenedIcon: menuOpenedIcon,
  helpIcon: helpIcon,
  smileyFaceIcon: smileyFaceIcon
};
