import React, { Component } from "react";
import moment from "moment";

import {
  FieldContainer,
  FixedWidthLabel,
  LargeButton,
  SmallButton
} from "../shared/styledComponents";

class SickLeaveFormSelector extends Component {
  render() {
    return (
      <div style={{ marginTop: "40px" }}>
        {moment().year() === this.props.currentYear && (
          <FieldContainer>
            <FixedWidthLabel>
              <LargeButton
                type="button"
                onClick={this.props.showCurrentSickLeaveForm}
              >
                I am on sick leave now
              </LargeButton>
            </FixedWidthLabel>

            <FixedWidthLabel textAlign="left">
              Select this to inform your manager that you are off sick
            </FixedWidthLabel>
          </FieldContainer>
        )}
        <FieldContainer>
          <FixedWidthLabel>
            <LargeButton
              type="button"
              onClick={this.props.showCompleteSickLeaveForm}
            >
              Complete sick leave form
            </LargeButton>
          </FixedWidthLabel>
          <FixedWidthLabel textAlign="left">
            Select this to complete a sick leave form after returning to work
          </FixedWidthLabel>
        </FieldContainer>
        <SmallButton
          style={{ marginTop: "50px" }}
          onClick={this.props.toggleFormSelector}
        >
          Cancel
        </SmallButton>
      </div>
    );
  }
}

export default SickLeaveFormSelector;
