import React from "react";
import { FilterControlTitle, FilterButton } from "../shared/styledComponents";
import styled from "styled-components";
import { theme } from "../../constants";
import { Check } from "styled-icons/fa-solid";

const FilterControl = styled.div`
  position: absolute;
  top: 43px;
  right: 12px;
  width: 200px;
  height: auto;
  background-color: white;
  padding: 10px;
  border: 1px solid ${theme.colors.pwg};
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  z-index: 100;
`;

const Caret = styled.div`
  position: absolute;
  top: -6px;
  right: calc(5% + 10px);
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border: solid ${theme.colors.pwg};
  border-width: 1px 0 0 1px;
  background-color: white;
`;

const CheckBoxContainer = styled.span`
  width: 30px;
`;

class PWGCalendarFilterControl extends React.Component {
  getAllDepartmentIds = () => {
    let allDepartmentIds = this.props.departments.map(department => department.id);
    return allDepartmentIds;
  };
  isDepartmentSelected = id => {
    // If only showing user's own leave, then do not tick any department names
    if (this.props.calendarFilterState.isShowMyLeaveOnly)
      return false;
    // If showing own department, then tick department if it is user's department
    if (this.props.calendarFilterState.isShowMyDepartmentOnly && id !== this.props.userDepartmentId)
      return false;
    // Tick department names if they are included in calendarFilterState
    for (let i = 0; i < this.props.calendarFilterState.displayedDepartments.length; i++) {
      if (this.props.calendarFilterState.displayedDepartments[i] === id)
        return true;
    }
    return false;
  };
  isShowingAllDepartments = id => {
    // Return true if all departments are being shown
    if (this.props.departments.length === 0)
      return false;
    for (let i = 0; i < this.props.departments.length; i++) {
      let departmentIsIncluded = false;
      for (let a = 0; a < this.props.calendarFilterState.displayedDepartments.length; a++) {
        if (this.props.departments[i].id === this.props.calendarFilterState.displayedDepartments[a])
          departmentIsIncluded = true;
      }
      if (!departmentIsIncluded)
        return false
    }
    return true;
  };
  updateFilter = selection => {
    let newCalendarFilterState = this.props.calendarFilterState;

    if (selection === 'MyLeaveOnly') {
      newCalendarFilterState.isShowMyLeaveOnly = true;
      newCalendarFilterState.isShowMyDepartmentOnly = false;
      newCalendarFilterState.displayedDepartments = []
    }
    else if (selection === 'MyDepartmentOnly') {
      let arrayWithUserDepartment = [];
      arrayWithUserDepartment.push(this.props.userDepartmentId);
      newCalendarFilterState.isShowMyLeaveOnly = false;
      newCalendarFilterState.isShowMyDepartmentOnly = true;
      newCalendarFilterState.displayedDepartments = arrayWithUserDepartment;
    }
    else if (selection === 'AllDepartments') {
      newCalendarFilterState.isShowMyLeaveOnly = false;
      newCalendarFilterState.isShowMyDepartmentOnly = false;
      newCalendarFilterState.displayedDepartments = this.getAllDepartmentIds();
    }
    else {
      let newDisplayedDepartments = this.props.calendarFilterState.displayedDepartments;

      if (this.props.calendarFilterState.displayedDepartments.indexOf(selection) === -1)
        newDisplayedDepartments.push(selection);
      else
        newDisplayedDepartments.splice(this.props.calendarFilterState.displayedDepartments.indexOf(selection), 1);

      newCalendarFilterState.isShowMyLeaveOnly = false;
      newCalendarFilterState.isShowMyDepartmentOnly = false;
      newCalendarFilterState.displayedDepartments = newDisplayedDepartments;
    }

    this.props.updateCalendarFilter(newCalendarFilterState);
  };
  render() {
    return (
      <FilterControl>
        <FilterControlTitle>Show on calendar</FilterControlTitle>
        <FilterButton onClick={() => this.updateFilter('MyLeaveOnly')}>
          <CheckBoxContainer>
            {this.props.calendarFilterState.isShowMyLeaveOnly === true && <Check size={12} />}
          </CheckBoxContainer>
          My leave only
        </FilterButton>
        <FilterButton onClick={() => this.updateFilter('MyDepartmentOnly')}>
          <CheckBoxContainer>
            {this.props.calendarFilterState.isShowMyDepartmentOnly === true && <Check size={12} />}
          </CheckBoxContainer>
          My department only
        </FilterButton>
        <FilterButton
          onClick={() => this.updateFilter('AllDepartments')}
          style={{
            marginBottom: "10px",
            paddingBottom: "15px",
            borderBottom: "1px solid #aeaeae"
          }}
        >
          <CheckBoxContainer>
            {this.isShowingAllDepartments() === true && <Check size={12} />}
          </CheckBoxContainer>
          All departments
        </FilterButton>

        {this.props.departments.length === 0
          &&
          <FilterButton>
            <CheckBoxContainer />
            Loading more...
          </FilterButton>
        }
        {this.props.departments.map(department =>
          <FilterButton key={department.id} onClick={() => this.updateFilter(department.id)}>
            <CheckBoxContainer>
              {this.isDepartmentSelected(department.id) && <Check size={12} />}
            </CheckBoxContainer>
            {department.description}
          </FilterButton>
        )}
        <button
          style={{
            textAlign: "center",
            height: "35px",
            width: "100%",
            cursor: "pointer",
            marginTop: "10px"
          }}
          onClick={this.props.toggleFilter}
        >
          Close
        </button>
        <Caret />
      </FilterControl>
    );
  }
}

export default PWGCalendarFilterControl;
