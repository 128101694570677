const versionManager = {
  getAllVersionUpdateNotes: (versionNotes) => {
    let allVersionNotes = [];
    for (let i = 0; i < versionNotes.length; i++) {
      allVersionNotes.push(...versionNotes[i].notes);
    }
    return allVersionNotes;
  },
  isUsingLatestVersion: (version, userVersion) => {
    return version === userVersion;
  },
  isVersionNewerThanUserVersion: (version, userVersion) => {
    if (userVersion === null)
      return true;

    if (version === null)
      console.log("Error - version checked cannot find current version in format 'x.x.x'");

    let versionParts = versionManager.getVersionParts(version);
    let userVersionParts = versionManager.getVersionParts(userVersion);

    if (versionParts === null || userVersionParts === null) {
      console.log("Error - version checker cannot recognize one of these versions: " + version + " or " + userVersion + ". Should be in format 'x.x.x'");
      return false;
    }

    if (versionParts.majorVersion > userVersionParts.majorVersion)
      return true;
    else if (versionParts.majorVersion < userVersionParts.majorVersion)
      return false;
    if (versionParts.minorVersion > userVersionParts.minorVersion)
      return true;
    else if (versionParts.minorVersion < userVersionParts.minorVersion)
      return false;
    return versionParts.build > userVersionParts.build;
  },
  getVersionParts: versionString => {
    let versionParts = versionString.split('.');
    if (versionParts.length !== 3) {
      return null;
    }
    return {
      majorVersion: Number(versionParts[0]),
      minorVersion: Number(versionParts[1]),
      build: Number(versionParts[2])
    };
  },
  getHeading: (lastVersionUsed, currentVersion, firstUseHeading) => {
    if (lastVersionUsed === null)
      return firstUseHeading;
    else
      return "New Stuff Added";
  },
  getVersionNotes: (lastVersionUsed, currentVersion, firstUseText, versionNotes) => {
    let versionTextData = {
      firstPara: "We've made the following changes since last time you were here:",
      updates: []
    };

    if (lastVersionUsed === null) {
      versionTextData.firstPara = firstUseText;
      return versionTextData;
    }

    for (let i = 0; i < versionNotes.length; i++) {
      if (versionManager.isVersionNewerThanUserVersion(versionNotes[i].version, lastVersionUsed)) {
        versionTextData.updates.push(...versionNotes[i].notes)
      }
    }

    return versionTextData;
  }
};

export default versionManager;
