import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const database = {
  call: (url, params) => {
    //console.log("Calling async request", `${baseUrl}/${url}`, params);
    return axios
      .post( `${baseUrl}/${url}`, params)
      .catch(function(error) { return error.response; });
  }
};

// @Incomplete - add in cancellation option later to be called on componentWillUnmount

// const cancelToken = axios.CancelToken;
// const source = cancelToken.source();

//   // cancel: () => {
//   //   source.cancel();
//   //   console.log("Should have cancelled all requests");
//   // }
