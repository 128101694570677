import React, { Component } from "react";

import {
  Cell,
  HeadCell,
  Header,
  Row,
  Table,
  TableContainer
} from "../shared/styledComponents";

class AdminStaffTable extends Component {
  render() {
    if (this.props.data.length < 1) {
      return <p>{this.props.isLoading ? "Loading" : "No users in database"}</p>;
    } else {
      return (
        <TableContainer>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Current year: {this.props.currentYear}</div>
            <div>Public holidays: {this.props.publicHolidayDays}</div>
            <div>Mandatory holiday days: {this.props.mandatoryHolidayDays}</div>
          </div>
          <p>Click a member of staff to view and edit details.</p>
          <Table>
            <Header>
              <tr>
                <HeadCell>Name</HeadCell>
                <HeadCell>Manager</HeadCell>
                <HeadCell>Leave days</HeadCell>
                <HeadCell>
                  Leave booked<br />or requested
                </HeadCell>
                <HeadCell>
                  Leave<br />remaining
                </HeadCell>
                <HeadCell>
                  Sick days<br />
                </HeadCell>
              </tr>
            </Header>
            <tbody>
              {this.props.data.map(user => {
                const remainingDays =
                  user.entitledHolidayDays - user.leaveDaysUsed;
                const remainingDaysStyle =
                  remainingDays < 0 ? { color: "red" } : {};
                const remainingDaysWithoutMandatoryDays =
                  remainingDays - this.props.mandatoryHolidayDays;

                return (
                  <Row
                    style={{ cursor: "pointer" }}
                    key={user.id}
                    isHighlightOnHover={true}
                    onClick={() => {
                      this.props.editPerson(user.id);
                    }}
                  >
                    <Cell>
                      <small>
                        {user.firstName} {user.lastName}
                      </small>
                      <small>
                        {user.isManager === 1 && (
                          <strong>
                            <br />Manager
                          </strong>
                        )}
                        {user.isAdmin === 1 && (
                          <strong>
                            <br />Leave App Admin
                          </strong>
                        )}
                      </small>
                    </Cell>
                    <Cell>
                      <small>{user.manager}</small>
                    </Cell>
                    <Cell>
                      <small>{user.entitledHolidayDays}</small>
                    </Cell>
                    <Cell>
                      <small>{user.leaveDaysUsed}</small>
                    </Cell>
                    <Cell>
                      <small style={remainingDaysStyle}>
                        {remainingDaysWithoutMandatoryDays}
                      </small>
                    </Cell>
                    <Cell>
                      <small>{user.sickDaysUsed}</small>
                    </Cell>
                  </Row>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
      );
    }
  }
}

export default AdminStaffTable;
