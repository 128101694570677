import React, { Component } from "react";

import {
  Cell,
  HeadCell,
  Header,
  Row,
  Table,
  TableContainer
} from "../shared/styledComponents";
import moment from "moment";
import StatusLabel from "../shared/statusLabel";
import { dayCalculationUtilities } from "../shared/logic";

class AdminStaffHolidayRequestsTable extends Component {
  render = () => {
    if (this.props.holidayRequests.length < 1)
      return <p>No holiday requests</p>;
    else {
      return (
        <TableContainer style={{ marginBottom: "7px" }}>
          <Table>
            <Header>
              <tr>
                <HeadCell>From</HeadCell>
                <HeadCell>To</HeadCell>
                <HeadCell>Days</HeadCell>
                <HeadCell>Status</HeadCell>
                <HeadCell>Details</HeadCell>
                <HeadCell />
              </tr>
            </Header>
            <tbody>
              {this.props.holidayRequests.map(request => {
                return (
                  <Row key={request.id}>
                    <Cell>
                      {dayCalculationUtilities.getFormattedDate(
                        moment(request.startDate)
                      )}
                    </Cell>
                    <Cell>
                      {dayCalculationUtilities.getFormattedDate(
                        moment(request.endDate)
                      )}
                    </Cell>
                    <Cell>{request.days}</Cell>
                    <Cell>
                      <StatusLabel
                        statusCode={request.status}
                        isAddedByAdmin={
                          request.createdByUserId !== request.userId
                        }
                        createdByUserName={
                          request.createdByUserId === this.props.userId
                            ? "me"
                            : request.createdByUserName
                        }
                      />
                    </Cell>
                    <Cell>
                      {request.description && `Notes: ${request.description}`}
                      <p>
                        {request.rejectionReason &&
                          `Rejection: ${request.rejectionReason}`}
                      </p>
                    </Cell>
                    <Cell>
                      {request.createdByUserId === this.props.userId &&
                        request.status !== "authorized" && (
                          <button
                            onClick={() => this.props.deleteHoliday(request.id)}
                            style={{ cursor: "pointer" }}
                          >
                            Remove
                          </button>
                        )}
                    </Cell>
                  </Row>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
      );
    }
  };
}

export default AdminStaffHolidayRequestsTable;
