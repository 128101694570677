import {theme} from "../../constants";
import {dayCalculationUtilities} from "./logic";

export const formValidationHelper = {
  error: (message, messageAlert) => {
    messageAlert(message, 2000);
    return false;
  },
  getDatepickerHighlightClassName: (isHighlight) => {
    return isHighlight ? "pwg-highlighted-datepicker-input" : "pwg-datepicker-input";
  },
  getInputStyle: (isHighlight) => {
    return isHighlight ? { border: `1px solid ${theme.colors.form.invalidInputHighlight}` } : {};
  },
  getLabelStyle: (isHighlight) => {
    return isHighlight ? { color: theme.colors.form.invalidInputHighlight } : {};
  },
  removeHighlight: (inputHighlights, highlight) => {
    let updatedInputHighlights = inputHighlights;
    updatedInputHighlights[highlight] = false;
    return updatedInputHighlights;
  },
  validateLeaveRequestForm: (formData, messageAlert, inputHighlights, holidayRequests, remainingDays, allHolidayDays) => {
    let isValid = true;
    if (formData.days > remainingDays) {
      isValid = formValidationHelper.error("You do not have enough leave days left", messageAlert);
    }
    if (formData.startDate === undefined) {
      isValid = formValidationHelper.error("Please enter start date", messageAlert);
      inputHighlights.isStartDateHighlighted = true;
    }
    if (formData.endDate === undefined) {
      isValid = formValidationHelper.error("Please enter end date", messageAlert);
      inputHighlights.isEndDateHighlighted = true;
    }
    if (formData.startDate !== undefined && formData.endDate !== undefined && formData.days <= 0) {
      isValid = formValidationHelper.error("All days requested are already holiday days", messageAlert);
      inputHighlights.isStartDateHighlighted = true;
      inputHighlights.isEndDateHighlighted = true;
    }
    if (formData.startDate !== undefined && formData.endDate !== undefined) {
      if (dayCalculationUtilities.isDaysOverlappingWithDateRange(formData.startDate, formData.endDate, allHolidayDays)) {
        isValid = formValidationHelper.error("Dates cannot overlap with existing holiday", messageAlert);
        inputHighlights.isStartDateHighlighted = true;
        inputHighlights.isEndDateHighlighted = true;
      }
    }
    return {
      isValid: isValid,
      inputHighlights: inputHighlights
    };
  },
  validateStartDateBeforeOrEqualToEndDate: (startDate, endDate, userHasEditedEndDate, messageAlert) => {
    if (userHasEditedEndDate && !dayCalculationUtilities.isStartDateBeforeOrEqualToEndDate(startDate, endDate))
      return formValidationHelper.error("Start date must be before end date", messageAlert);
    return true;
  },
  validateSickLeaveForm: (formData, messageAlert, inputHighlights, allSickLeaveDays, isEditingSickLeave, currentSickLeaveData) => {
    let isValid = true;
    if (formData.isStillOnSickLeave) {
      if (dayCalculationUtilities.isAfterNextDay(formData.sicknessStartDate)) {
        isValid = formValidationHelper.error("Notification of illness cannot be more than one day in the future", messageAlert)
      }
      if (formData.sicknessStartDate !== undefined) {
        if (dayCalculationUtilities.isDaysOverlappingWithDateRange(formData.sicknessStartDate, formData.sicknessStartDate, allSickLeaveDays)) {
          isValid = formValidationHelper.error("Sick leave is already recorded for selected day", messageAlert);
          inputHighlights.isSicknessStartDateHighlighted = true;
        }
      }
    }
    if (!formData.isStillOnSickLeave) {
      if (formData.sicknessStartDate === undefined) {
        isValid = formValidationHelper.error("Please enter sickness start date", messageAlert);
        inputHighlights.isSicknessStartDateHighlighted = true;
      }
      if (formData.sicknessEndDate === undefined) {
        isValid = formValidationHelper.error("Please enter sickness end date", messageAlert);
        inputHighlights.isSicknessEndDateHighlighted = true;
      }
      if (formData.returnToWorkDate === undefined) {
        isValid = formValidationHelper.error("Please enter sickness return to work date", messageAlert);
        inputHighlights.isReturnToWorkDayHighlighted = true;
      }
      if (formData.sicknessReasons === "") {
        isValid = formValidationHelper.error("Please enter brief description of reasons", messageAlert);
        inputHighlights.isSicknessReasonsHighlighted = true;
      }
      if (!formData.hasConfirmedInformationWillBeUsed) {
        isValid = formValidationHelper.error("Please read and confirm unfit for work confirmation check box", messageAlert);
        inputHighlights.isHasConfirmedInformationWillBeUsedHighlighted = true;
      }
      if (!formData.hasConfirmedUnfitForWork) {
        isValid = formValidationHelper.error("Please read and confirm last check box", messageAlert);
        inputHighlights.isHasConfirmedUnfitForWorkHighlighted = true;
      }

      let unavailableSickLeaveDays = allSickLeaveDays;
      if (isEditingSickLeave)
        unavailableSickLeaveDays = dayCalculationUtilities.removeDateFromArrayOfDates(allSickLeaveDays, currentSickLeaveData.sicknessStartDate);

      if (formData.sicknessStartDate !== undefined && formData.sicknessEndDate !== undefined) {
        if (dayCalculationUtilities.isDaysOverlappingWithDateRange(formData.sicknessStartDate, formData.sicknessEndDate, unavailableSickLeaveDays)) {
          isValid = formValidationHelper.error("Dates cannot overlap with existing sick leave", messageAlert);
          inputHighlights.isSicknessStartDateHighlighted = true;
          inputHighlights.isSicknessEndDateHighlighted = true;
        }
      }

    }

    return {
      isValid: isValid,
      inputHighlights: inputHighlights
    };
  },
  validateEditStaffForm: (formData, messageAlert, inputHighlights) => {
    let isValid = true;

    if (formData.managerId === 'Select Manager') {
      isValid = formValidationHelper.error("Please select manager", messageAlert);
      inputHighlights.isManagerHighlighted = true;
    }
    if (formData.entitledHolidayDays === '') {
      isValid = formValidationHelper.error("Please enter leave days", messageAlert);
      inputHighlights.isEntitledHolidayDaysHighlighted = true;
    }
    if (isNaN(formData.entitledHolidayDays)) {
      isValid = formValidationHelper.error("Please enter a valid number of holiday days", messageAlert);
      inputHighlights.isEntitledHolidayDaysHighlighted = true;
    }
    if (Number(formData.entitledHolidayDays) < 0) {
      isValid = formValidationHelper.error("Holiday days cannot be less than zero", messageAlert);
      inputHighlights.isEntitledHolidayDaysHighlighted = true;
    }
    if (Number(formData.entitledHolidayDays) > 366) {
      isValid = formValidationHelper.error("Not enough days in the year!", messageAlert);
      inputHighlights.isEntitledHolidayDaysHighlighted = true;
    }
    if (Number(formData.entitledHolidayDays) % 1 !== 0) {
      isValid = formValidationHelper.error("Entitled holiday days must be a whole number", messageAlert);
      inputHighlights.isEntitledHolidayDaysHighlighted = true;
    }

    return {
      isValid: isValid,
      inputHighlights: inputHighlights
    };
  },
  validateAddFixedHolidayDayForm: (formData, messageAlert, inputHighlights, fixedHolidayDays) => {
    let isValid = true;

    if (formData.name === "") {
      isValid = formValidationHelper.error("Please enter name of fixed holiday day", messageAlert);
      inputHighlights.isFixedHolidayDayNameHighlighted = true;
    }
    if (formData.fixedHolidayDate === undefined || formData.fixedHolidayDate === null) {
      isValid = formValidationHelper.error("Please enter date of fixed holiday day", messageAlert);
      inputHighlights.isFixedHolidayDateHighlighted = true;
    }

    if (formData.fixedHolidayDate !== undefined && formData.fixedHolidayDate !== undefined) {
      for (let i = 0; i < fixedHolidayDays.length; i++) {
        if (formData.fixedHolidayDate.isSame(fixedHolidayDays[i], 'day')) {
          isValid = formValidationHelper.error("Fixed holiday date already selected for this year", messageAlert);
          inputHighlights.isFixedHolidayDateHighlighted = true;
        }
      }
    }

    return {
      isValid: isValid,
      inputHighlights: inputHighlights
    };
  }
};
