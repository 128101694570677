import moment from "moment";
import { datepickerFormat } from "../../constants";

export const returnOrderedArrayOfDates = dates => {
  const getDate = day => {
    if (day.date) return day.date;
    else if (day.startDate) return day.startDate;
    else return day.sicknessStartDate;
  };
  dates.sort((a, b) => {
    let tempA = getDate(a);
    let tempB = getDate(b);
    return tempA > tempB ? 1 : -1;
  });
  return dates;
};

export const dayCalculationUtilities = {
  getFormattedDate: day => {
    return day ? moment(day).format(datepickerFormat) : "";
  },
  convertDateToStringForSubmission: day => {
    return day === undefined ? null : day.format("YYYY-MM-DD");
  },
  convertDateStringArrayIntoMomentArray: dates => {
    return dates.map(day => {
      return moment(day.date);
    });
  },
  getArrayOfIndividualLeaveDays: leaveRanges => {
    let allLeaveDays = [];
    for (let i = 0; i < leaveRanges.length; i++) {
      let startDate =
        typeof leaveRanges[i].startDate !== "undefined"
          ? leaveRanges[i].startDate
          : leaveRanges[i].sicknessStartDate;
      let endDate =
        typeof leaveRanges[i].endDate !== "undefined"
          ? leaveRanges[i].endDate
          : leaveRanges[i].sicknessEndDate;
      if (endDate === undefined || endDate === null)
        allLeaveDays.push(moment(startDate));
      else {
        let dates = dayCalculationUtilities.getDaysBetweenDatesAsArray(
          moment(startDate),
          moment(endDate)
        );
        allLeaveDays.push(...dates);
      }
    }
    return allLeaveDays;
  },
  getDaysBetweenDatesAsArray: (startDate, endDate) => {
    let dates = [];
    startDate = moment(startDate);
    endDate = moment(endDate);
    while (!startDate.isAfter(endDate, "day")) {
      let dateToAdd = moment(startDate.format("YYYY-MM-DD"));
      dates.push(dateToAdd);
      startDate = startDate.add(1, "days");
    }
    return dates;
  },
  getWorkingDaysBetweenDatesAsArray: (startDate, endDate) => {
    let dates = [];
    startDate = moment(startDate);
    endDate = moment(endDate);
    while (!startDate.isAfter(endDate, "day")) {
      if (!dayCalculationUtilities.isWeekend(startDate)) {
        let dateToAdd = moment(startDate.format("YYYY-MM-DD"));
        dates.push(dateToAdd);
      }
      startDate = startDate.add(1, "days");
    }
    return dates;
  },
  getNextWorkingDay: day => {
    let nextDay = day.clone();
    nextDay.add(1, "day");
    while (dayCalculationUtilities.isWeekend(nextDay)) {
      nextDay.add(1, "day");
    }
    return nextDay;
  },
  getNumberOfLeaveDays: leaves => {
    let leaveDaysCount = 0;
    for (let i = 0; i < leaves.length; i++) {
      leaveDaysCount += leaves[i].days;
    }
    return leaveDaysCount;
  },
  getNumberOfMandatoryHolidayDays: fixedHolidayDays => {
    let mandatoryHolidayDayCount = 0;
    for (let i = 0; i < fixedHolidayDays.length; i++) {
      if (fixedHolidayDays[i].isPublicHoliday !== 1)
        mandatoryHolidayDayCount++;
    }
    return mandatoryHolidayDayCount;
  },
  isDaysOverlappingWithDateRange: (dateRangeStart, dateRangeEnd, days) => {
    for (let i = 0; i < days.length; i++) {
      if (
        days[i].isSameOrAfter(dateRangeStart, "days") &&
        days[i].isSameOrBefore(dateRangeEnd, "days")
      )
        return true;
    }
    return false;
  },
  isAfterNextDay: date => {
    return date.isAfter(moment().add(1, "days"), "day");
  },
  isDayInRangeOfDays: (day, arrayOfDays) => {
    for (let i = 0; i < arrayOfDays.length; i++) {
      if (moment(arrayOfDays[i]).isSame(moment(day), "days")) return false;
    }
    return true;
  },
  isInThePast: date => {
    return date.isBefore(moment(), "day");
  },
  isStartDateBeforeOrEqualToEndDate: (startDate, endDate) => {
    if (startDate === undefined || endDate === undefined) return true;
    return startDate.isSameOrBefore(endDate);
  },
  isStartDateBeforeEndDate: (startDate, endDate) => {
    if (startDate === undefined || endDate === undefined) return true;
    return startDate.isBefore(endDate, "day");
  },
  isStartDateSameAsEndDate: (startDate, endDate) => {
    if (startDate === undefined || endDate === undefined) return false;
    return startDate.isSame(endDate, "day");
  },
  isWeekday: day => {
    return !dayCalculationUtilities.isWeekend(moment(day));
  },
  isWeekend: day => {
    return moment(day).day() === 0 || moment(day).day() === 6;
  },
  removeDateFromArrayOfDates: (arrayOfDays, day) => {
    for (let i = 0; i < arrayOfDays.length; i++) {
      if (moment(arrayOfDays[i]).isSame(moment(day), "days"))
        arrayOfDays.splice(i, 1);
    }
    return arrayOfDays;
  }
};
