import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import App from "./App";

ReactDOM.render(
  <BrowserRouter>
    <CookiesProvider>
        <Route path="/" component={App} />
    </CookiesProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
