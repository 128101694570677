import React, { Component } from "react";

class StatusLabel extends Component {
  render() {
    return (
      <span>
        {this.props.statusCode === "pending" && (
          <span style={{ fontWeight: "bold" }}>Pending</span>
        )}
        {this.props.statusCode === "authorized" && "Authorised"}
        {this.props.statusCode === "rejected" && "Rejected"}
        {this.props.statusCode !== "pending" &&
        this.props.statusCode !== "authorized" &&
        this.props.statusCode !== "rejected" &&
        this.props.statusCode}
        {this.props.isAddedByAdmin && <div>(Added by {this.props.createdByUserName})</div>}
      </span>
    );
  }
}

export default StatusLabel;
