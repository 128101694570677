import React, { Component } from "react";
import {BarChartBarContainer, BarChartUnit} from "./styledComponents";

class UserLeaveGraphic extends Component {
  render() {
    const leaveDaysRemaining = this.props.entitledHolidayDays - this.props.mandatoryHolidayDays - this.props.leaveDaysUsed;

    let dayIcons = [];

    for (let i = 0; i < this.props.mandatoryHolidayDays; i++) {
      dayIcons.push(<BarChartUnit backgroundColour="#676767" key={'Mandatory-day-' + i}/>);
    }
    for (let i = 0; i < this.props.leaveDaysUsed; i++) {
      if (this.props.leaveDaysUsed - i < 1)
        dayIcons.push(<BarChartUnit backgroundColour="#669933" width={8} key={'Used-half-day-' + i} />);
      else
        dayIcons.push(<BarChartUnit backgroundColour="#669933" key={'Used-day-' + i} />);
    }
    for (let i = 0; i < leaveDaysRemaining; i++) {
      if (leaveDaysRemaining - i < 1)
        dayIcons.push(<BarChartUnit backgroundColour="#c5651f" width={8} key={'Remaining-half-day-' + i} />);
      else
        dayIcons.push(<BarChartUnit backgroundColour="#c5651f" key={'Remaining-day-' + i} />);
    }

    return (
      <BarChartBarContainer>
        {dayIcons.map(day => day)}
      </BarChartBarContainer>
    );
  }
};

export default UserLeaveGraphic;
