import styled from "styled-components";
import { theme } from "../../constants";

export const AppContainer = styled.div`
  position: absolute;
  left: 50%;
  margin-top: 0px;
  margin-left: -480px;
  font-family: ${theme.font};
  width: 960px;
  font-size: 13px;
`;

export const AppContentContainer = styled.div`
  position: absolute;
  top: 89px;
  border: 1px solid #bfbfbf;
  background-color: white;
  width: 100%;
`;

export const AppPermissionsTitle = styled.div`font-weight: bold;`;

export const BarChartBarContainer = styled.div`
  display: flex;
  justify-content: flex-left;
  align-items: center;
`;

export const BarChartUnit = styled.div`
  margin: 0px;
  height: 16px;
  width: ${props => props.width === undefined ? "16px" : props.width + "px"};
  background-color: ${props => props.backgroundColour !== null ? props.backgroundColour : "#ffffff"};
`;

export const Button = styled.button`
  cursor: pointer;
  padding: 7px 15px 7px 15px;
`;

export const CalendarPlaceholder = styled.div`
  width: 100%;
  height: 275px;
  padding-top: 250px;
  text-align: center;
  background-color: #efefef;
`;

export const CalendarPopupContainer = styled.div`
  padding: 10px 15px 15px 15px;
  width: 400px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid ${theme.colors.border};
  position: absolute;
  top: 180px;
  right: calc(5% + 71px);
  margin-left: -200px;
  z-index: 100;
`;

export const Cell = styled.td`
  padding: 4px;
  text-align: ${props => props.textAlign || "left"};
  border: solid 1px ${theme.colors.table.tableCellBorder};
`;

export const CheckBoxLabelText = styled.span`cursor: pointer;`;

export const DateDisplay = styled.div`
  background-color: ${theme.colors.dateBackground};
  vertical-align: center;
  padding: 5px 10px 5px 10px;
  margin-left: 20px;
`;

export const ErrorMessage = styled.div`color: #ff0000;`;

export const FieldContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  justify-items: space-between;
  align-items: center;
`;

export const FilterButton = styled.div`
  display: flex;
  justify-content: "space-between";
  align-items: "center",
  border: none;
  margin-bottom: 7px;
  padding: 5px;
  width: 100%;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  
  &:hover {
    background-color: ${theme.colors.lightGreen};
  }
`;

export const FilterControlTitle = styled.div`
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${theme.colors.border};
`;

export const FixedWidthInput = styled.input`
  width: 35%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #dedede;
`;

export const FixedWidthLabel = styled.label`
  display: inline-block;
  width: 35%;
  text-align: ${props => props.textAlign || "right"};
  padding-right: 25px;
  vertical-align: center;
`;

export const FixedWidthSelect = styled.select`width: 45%;`;

export const HeadCell = styled.th`
  padding: 4px;
  text-align: left;
  border: solid 1px ${theme.colors.table.tableCellBorder};
`;

export const Header = styled.thead`
  background-color: ${theme.colors.table.tableHeader};
`;

export const HeaderContainer = styled.div`
  margin-top: -8px;
  padding: 0px;
  height: 80px;
`;

export const HeaderControls = styled.div`
  position: absolute;
  top: 12px;
  right: calc(50% - 480px);
  height: auto;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
`;

export const HeaderMenuIcon = styled.div`
  text-align: center;
  color: ${theme.colors.pwg};
  height: 28px;
  width: 28px;
  margin-left: 7px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const HeaderTitle = styled.div`
  color: ${theme.colors.dashboardTitle};
  font-size: 1.7em;
  letter-spacing: 3px;
`;

export const LargeButton = styled.button`
  width: 250px;
  height: 70px;
  padding: 10px;
  background-color: ${theme.colors.dateBackground};
  border: 1px solid #cdcdcd;
  border-radius: 7px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: ${theme.colors.pageHeading};
  }
`;

export const LargeNumberDisplay = styled.div`
  font-size: 3em;
  letter-spacing: -3px;
  color: ${theme.colors.pwg}
`;

export const LoginFormContainer = styled.form`
  width: 800px;
  margin-top: 7px;
`;

export const Message = styled.div`
  width: 100%;
  text-align: center;
  padding-top: ${props => props.height ? props.height : '150px'};
  padding-bottom: ${props => props.height ? props.height : '200px'};
`;

export const PageHeading = styled.h1`
  color: ${theme.colors.pageHeading};
  font-size: 16px;
`;

export const RedSpan = styled.span`color: red;`;

export const Row = styled.tr`
  &:nth-child(odd) {
    background-color: ${theme.colors.table.rowBackgroundColourOdd};
  }
  &:nth-child(even) {
    background-color: ${theme.colors.table.rowBackgroundColourEven};
  }
  &:hover {
    background-color: ${props =>
      props.isHighlightOnHover &&
      theme.colors.table.rowBackgroundColourHighlight};
  }
`;

export const SmallButton = styled.button`
  cursor: pointer;
  padding: 2px 10px 2px 10px;
`;

export const SmallYearSelectorButton = styled(SmallButton)`
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  color: ${props => props.isSelected && "#ffffff"}
  background-color: ${props => props.isSelected && theme.colors.pwg};
`;

export const SmallFilterButton = styled(SmallButton)`
  color: ${props => props.isSelected && "#ffffff"};
  border: ${props =>
    props.isSelected
      ? `1px solid ${theme.colors.darkBorder}`
      : `1px solid ${theme.colors.border}`};
  background-color: ${props => props.isSelected && theme.colors.pwg};
  background-image: ${props =>
    !props.isSelected &&
    !props.disabled &&
    `linear-gradient(${theme.colors.header.tabBackgroundLight}, ${theme.colors
      .header.tabBackground})`};
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TableContainer = styled.div`
  font-size: 13px;
  margin-top: 20px;
  padding-right: 20px;
`;

export const UserDetailsNameLabel = styled.div`
  font-weight: bold;
  font-size: 1.3em;
`;

export const YearSelectorControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  padding-bottom: 10px;
`;
