import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";

import { PageHeading } from "../shared/styledComponents";
import {
  FieldContainer,
  FixedWidthLabel,
  FixedWidthInput,
  FixedWidthSelect,
  RedSpan
} from "../shared/styledComponents";
import { database } from "../shared/database";
import { formValidationHelper } from "../shared/formValidationHelper";
import AdminStaffHolidayRequestsTable from "./adminStaffHolidayRequestsTable";
import AdminStaffSickLeavesTable from "./adminStaffSickLeavesTable";
import { dayCalculationUtilities } from "../shared/logic";

class AdminEditStaffForm extends Component {
  state = {
    userId: this.props.match.params.id,
    managerId: -1,
    firstName: "",
    lastName: "",
    jobTitle: "",
    isManager: false,
    isAdmin: false,
    leaveDaysUsed: -1,
    sickDaysUsed: -1,
    mandatoryHolidayDays: -1,
    entitledHolidayDays: -1,
    entitledHolidayDaysPrev: -1,
    managers: [],
    holidayRequests: [],
    isLoading: true,
    sickLeaves: [],
    inputHighlights: {
      isManagerHighlighted: false,
      isEntitledHolidayDaysHighlighted: false
    }
  };

  componentDidMount = async () => {
    this.loadUserData();
  };

  componentDidUpdate = prevProps => {
    if (this.props.currentYear !== prevProps.currentYear) {
      this.loadUserData();
    }
  };

  loadUserData = async () => {
    this.props.loadingMessages.addMessage(
      "Loading data for " + this.props.currentYear
    );

    const userData = await database.call("helpers/getUser/", {
      userId: this.props.match.params.id,
      token: this.props.token,
      year: this.props.currentYear
    });

    const managers = await database.call("myHolidays/managers", {
      token: this.props.token
    });

    const holidayRequests = await database.call(
      "myHolidays/holidayRequest/getAllByUser/",
      {
        userId: this.props.match.params.id,
        token: this.props.token,
        year: this.props.currentYear
      }
    );

    const sickLeaves = await database.call("sickLeave/getAllByUser/", {
      userId: this.props.match.params.id,
      token: this.props.token,
      year: this.props.currentYear
    });

    const fixedHolidayDays = await database.call(
      "myHolidays/fixedHolidayDays",
      {
        year: this.props.currentYear,
        token: this.props.token
      }
    );

    await this.setState({
      firstName: userData.data.firstName,
      lastName: userData.data.lastName,
      jobTitle: userData.data.jobTitle,
      isManager: userData.data.isManager,
      isAdmin: userData.data.isAdmin,
      managerId: userData.data.managerId,
      leaveDaysUsed: dayCalculationUtilities.getNumberOfLeaveDays(
        holidayRequests.data
      ),
      sickDaysUsed: dayCalculationUtilities.getNumberOfLeaveDays(
        sickLeaves.data
      ),
      mandatoryHolidayDays: dayCalculationUtilities.getNumberOfMandatoryHolidayDays(
        fixedHolidayDays.data
      ),
      entitledHolidayDays: userData.data.entitledHolidayDays,
      entitledHolidayDaysPrev: userData.data.entitledHolidayDays,
      holidayRequests: holidayRequests.data,
      sickLeaves: sickLeaves.data,
      managers: managers.data,
      isLoading: false
    });

    this.props.loadingMessages.removeMessage(
      "Loading data for " + this.props.currentYear
    );
  };

  addHolidayRequest = () => {
    this.props.history.push(
      "/myHoliday/holidayRequestsForm/" + this.props.match.params.id
    );
  };

  calculateLeaveDaysRemaining = () => {
    let leaveDaysRemaining =
      this.state.entitledHolidayDaysPrev -
      this.state.leaveDaysUsed -
      this.state.mandatoryHolidayDays;
    if (
      this.state.entitledHolidayDaysPrev !==
      Number(this.state.entitledHolidayDays)
    )
      leaveDaysRemaining +=
        " (" +
        (this.state.entitledHolidayDays -
          this.state.leaveDaysUsed -
          this.state.mandatoryHolidayDays) +
        " if current changes saved)";
    return leaveDaysRemaining;
  };

  cancelEditPerson = () => {
    this.props.history.push("/adminStaff");
  };

  deleteHoliday = holidayRequestId => {
    confirmAlert({
      message: "Are you sure you want to delete the holiday request?",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            this.deleteHolidayFromDatabase(holidayRequestId);
          }
        },
        {
          label: "Cancel"
        }
      ]
    });
  };

  deleteHolidayFromDatabase = async holidayRequestId => {
    this.props.loadingMessages.addMessage("Deleting leave request");

    const deleteHolidayRequestResponse = await database.call(
      "myHolidays/holidayRequest/delete",
      {
        holidayRequestId: holidayRequestId,
        token: this.props.token
      }
    );

    if (
      deleteHolidayRequestResponse &&
      deleteHolidayRequestResponse.data.ROW_COUNT === 1
    )
      this.loadUserData();
    this.props.loadingMessages.removeMessage("Deleting leave request");
  };

  handleSubmit = async event => {
    event.preventDefault();
    const validationResult = formValidationHelper.validateEditStaffForm(
      this.state,
      this.props.loadingMessages.alert,
      this.state.inputHighlights
    );
    if (validationResult.isValid) {
      this.updatePerson(this.state);
    } else {
      await this.setState({
        inputHighlights: validationResult.inputHighlights
      });
    }
  };

  returnToStaffList = () => {
    this.props.history.push("/adminStaff");
  };

  updatePerson = async formData => {
    this.props.loadingMessages.addMessage("Saving details");

    const setManagerResponse = await database.call("myHolidays/setManager", {
      userId: formData.userId,
      managerId: formData.managerId,
      token: this.props.token
    });

    const setEntitledHolidaysResponse = await database.call(
      "myHolidays/setEntitledHolidayDays",
      {
        userId: formData.userId,
        entitledHolidayDays: Number(formData.entitledHolidayDays),
        token: this.props.token,
        year: this.props.currentYear
      }
    );

    if (
      setManagerResponse.data.ROW_COUNT !== 1 ||
      setEntitledHolidaysResponse.data.ROW_COUNT !== 1
    )
      this.props.loadingMessages.alert(
        "An error occurred when saving staff details"
      );

    this.props.loadingMessages.removeMessage("Saving details");

    this.props.history.push("/adminStaff");
  };

  updateEntitledHolidayDays = async event => {
    await this.setState({
      entitledHolidayDays: event.currentTarget.value,
      inputHighlights: formValidationHelper.removeHighlight(
        this.state.inputHighlights,
        "isEntitledHolidayDaysHighlighted"
      )
    });
  };

  updateManagerId = async event => {
    await this.setState({
      managerId: event.currentTarget.value,
      inputHighlights: formValidationHelper.removeHighlight(
        this.state.inputHighlights,
        "isManagerHighlighted"
      )
    });
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        {this.state.isLoading ? (
          <p>Loading</p>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <PageHeading>Person details</PageHeading>
              <button
                type="button"
                onClick={this.returnToStaffList}
                style={{ cursor: "pointer", marginRight: "10px" }}
              >
                Return to staff list
              </button>
            </div>
            <hr />

            <form onSubmit={this.handleSubmit}>
              <FieldContainer>
                <FixedWidthLabel>Name</FixedWidthLabel>
                <FixedWidthLabel textAlign="left">
                  {this.state.firstName} {this.state.lastName}
                </FixedWidthLabel>
              </FieldContainer>

              <FieldContainer>
                <FixedWidthLabel>Job title</FixedWidthLabel>
                <FixedWidthLabel textAlign="left">
                  {this.state.jobTitle}
                </FixedWidthLabel>
              </FieldContainer>

              <FieldContainer>
                <FixedWidthLabel>Is Manager</FixedWidthLabel>
                <FixedWidthLabel textAlign="left">
                  {this.state.isManager === 1 ? "Yes" : "No"}
                </FixedWidthLabel>
              </FieldContainer>

              <FieldContainer>
                <FixedWidthLabel>Is Admin</FixedWidthLabel>
                <FixedWidthLabel textAlign="left">
                  {this.state.isAdmin === 1 ? "Yes" : "No"}
                </FixedWidthLabel>
              </FieldContainer>

              <FieldContainer>
                <FixedWidthLabel
                  style={formValidationHelper.getLabelStyle(
                    this.state.inputHighlights.isManagerHighlighted
                  )}
                >
                  <RedSpan>*</RedSpan> Manager:
                </FixedWidthLabel>
                <FixedWidthSelect
                  name="manager"
                  value={this.state.managerId}
                  onChange={this.updateManagerId}
                  style={formValidationHelper.getInputStyle(
                    this.state.inputHighlights.isManagerHighlighted
                  )}
                >
                  <option value={-1}>Select Manager</option>
                  {this.state.managers.map(manager => (
                    <option key={manager.id} value={manager.id}>
                      {manager.firstName} {manager.lastName}
                    </option>
                  ))}
                </FixedWidthSelect>
              </FieldContainer>

              <FieldContainer>
                <FixedWidthLabel
                  style={formValidationHelper.getLabelStyle(
                    this.state.inputHighlights.isEntitledHolidayDaysHighlighted
                  )}
                >
                  <RedSpan>*</RedSpan> {this.props.currentYear} Entitled holiday
                  days
                </FixedWidthLabel>
                <FixedWidthInput
                  type="number"
                  value={this.state.entitledHolidayDays}
                  onChange={this.updateEntitledHolidayDays}
                  style={formValidationHelper.getInputStyle(
                    this.state.inputHighlights.isEntitledHolidayDaysHighlighted
                  )}
                />
              </FieldContainer>

              <FieldContainer>
                <FixedWidthLabel textAlign="left" />
                <FixedWidthLabel
                  textAlign="right"
                  style={{
                    width: "45%",
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <button
                    type="button"
                    onClick={this.cancelEditPerson}
                    style={{ cursor: "pointer" }}
                  >
                    Cancel
                  </button>
                  <button type="submit" style={{ cursor: "pointer" }}>
                    Save changes
                  </button>
                </FixedWidthLabel>
              </FieldContainer>
            </form>

            <hr />
            <strong>{this.props.currentYear} Annual summary</strong>
            <form>
              <FieldContainer>
                <FixedWidthLabel>Leave days requested</FixedWidthLabel>
                <FixedWidthLabel textAlign="left">
                  {this.state.leaveDaysUsed}
                </FixedWidthLabel>
              </FieldContainer>

              <FieldContainer>
                <FixedWidthLabel>Mandatory holiday days</FixedWidthLabel>
                <FixedWidthLabel textAlign="left">
                  {this.state.mandatoryHolidayDays}
                </FixedWidthLabel>
              </FieldContainer>

              <FieldContainer>
                <FixedWidthLabel>Leave days remaining</FixedWidthLabel>
                <FixedWidthLabel textAlign="left">
                  {this.calculateLeaveDaysRemaining()}
                </FixedWidthLabel>
              </FieldContainer>

              <FieldContainer>
                <FixedWidthLabel>Sick days</FixedWidthLabel>
                <FixedWidthLabel textAlign="left">
                  {this.state.sickDaysUsed}
                </FixedWidthLabel>
              </FieldContainer>
            </form>

            <hr />
            <strong>{this.props.currentYear} Holiday Requests</strong>
            <br />
            <button
              type="button"
              onClick={this.addHolidayRequest}
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              Add holiday request
            </button>
            <AdminStaffHolidayRequestsTable
              userId={this.props.userId}
              staffUserId={this.props.match.params.id}
              holidayRequests={this.state.holidayRequests}
              deleteHoliday={this.deleteHoliday}
            />
            <hr />
            <strong>{this.props.currentYear} Sick Leave</strong>
            <AdminStaffSickLeavesTable sickLeaves={this.state.sickLeaves} />
          </div>
        )}
      </div>
    );
  }
}

export default AdminEditStaffForm;
