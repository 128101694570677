import React, { Component } from "react";
import { Flex, Box } from "rebass";
import { icons } from "../../assets/icons/icons";
import { Icon } from "./styledSideMenu";
import YearSelector from "./yearSelector";

import { LargeNumberDisplay } from "../shared/styledComponents";
import { SideMenuLink, SideMenuDiv, SideMenuWhiteDiv } from "./styledSideMenu";

class SideMenu extends Component {
  render() {
    return (
      <div>
        <SideMenuDiv style={{ marginRight: "20px" }}>
          <SideMenuWhiteDiv>
            <Flex>
              <Box width={9 / 10}>Welcome {this.props.userFirstName}</Box>
              <Box width={1 / 10}>
                <Icon src={icons["smileyFaceIcon"]} />
              </Box>
            </Flex>
          </SideMenuWhiteDiv>
          <SideMenuWhiteDiv>
            <YearSelector
              currentYear={this.props.currentYear}
              setCurrentYear={this.props.setCurrentYear}
            />
          </SideMenuWhiteDiv>
          <SideMenuLink
            to="/"
            isSelected={this.props.location.pathname === "/"}
          >
            Calendar
          </SideMenuLink>
          <SideMenuLink
            to="/myHoliday"
            isSelected={this.props.location.pathname === "/myHoliday"}
          >
            My holiday
          </SideMenuLink>
          <SideMenuLink
            to="/mySickLeave"
            isSelected={this.props.location.pathname === "/mySickLeave"}
          >
            My sick leave
          </SideMenuLink>
          <SideMenuLink
            to="/fixedHolidayDays"
            isSelected={this.props.location.pathname === "/fixedHolidayDays"}
          >
            Fixed holiday days
          </SideMenuLink>
          {this.props.isManager && (
            <div>
              <SideMenuWhiteDiv>My Team</SideMenuWhiteDiv>
              <SideMenuLink
                to="/teamOverview"
                isSelected={this.props.location.pathname === "/teamOverview"}
              >
                Overview
              </SideMenuLink>
              <SideMenuLink
                to="/staffHolidayRequests"
                isSelected={
                  this.props.location.pathname === "/staffHolidayRequests"
                }
              >
                Staff holiday requests{" "}
                {this.props.pendingLeaveRequestCount > 0 && (
                  <span style={{ color: "red" }}>
                    ({this.props.pendingLeaveRequestCount})
                  </span>
                )}
              </SideMenuLink>
              <SideMenuLink
                to="/staffSickLeave"
                isSelected={this.props.location.pathname === "/staffSickLeave"}
              >
                Staff sick leave{" "}
                {this.props.pendingSickLeaveCount > 0 && (
                  <span style={{ color: "red" }}>
                    ({this.props.pendingSickLeaveCount})
                  </span>
                )}
              </SideMenuLink>
            </div>
          )}
          {this.props.isAdmin && (
            <div>
              <SideMenuWhiteDiv>Admin</SideMenuWhiteDiv>
              <SideMenuLink
                to="/adminStaff"
                target="_self"
                isSelected={this.props.location.pathname === "/adminStaff"}
              >
                Staff
              </SideMenuLink>
              <SideMenuLink
                to="/adminYears"
                isSelected={this.props.location.pathname === "/adminYears"}
              >
                Years
              </SideMenuLink>
            </div>
          )}
          <SideMenuWhiteDiv>
            <LargeNumberDisplay>
              {this.props.remainingHolidayDays === null ? "--"
                :
                (this.props.remainingHolidayDays > 0 ? this.props.remainingHolidayDays : 0)
              }
            </LargeNumberDisplay>
            Unused leave days ({this.props.currentYear})
          </SideMenuWhiteDiv>
        </SideMenuDiv>
      </div>
    );
  }
}

export default SideMenu;
