import React, {Component} from "react";

import {Cell, HeadCell, Header, Row, Table, TableContainer} from "../shared/styledComponents";
import moment from "moment";
import SickLeaveFormStatusLabel from "../shared/sickLeaveFormStatusLabel";
import {dayCalculationUtilities} from "../shared/logic";

class AdminStaffSickLeavesTable extends Component {
  render = () => {
    if (this.props.sickLeaves.length < 1)
      return <p>No holiday requests</p>;
    else {
      return (
        <TableContainer>
          <Table>
            <Header>
              <tr>
                <HeadCell>Submitted</HeadCell>
                <HeadCell>Sick leave dates</HeadCell>
                <HeadCell>Days</HeadCell>
                <HeadCell>Status</HeadCell>
              </tr>
            </Header>
            <tbody>
            {this.props.sickLeaves.map((sickLeave) => {
              return (
                <Row key={sickLeave.id}>
                  <Cell>
                    {dayCalculationUtilities.getFormattedDate(moment(sickLeave.dateSubmitted))}
                  </Cell>
                  <Cell>
                    From {dayCalculationUtilities.getFormattedDate(moment(sickLeave.sicknessStartDate))}<br />
                    To {sickLeave.sicknessEndDate === null
                      ?
                      "(on sick leave now)"
                      :
                      dayCalculationUtilities.getFormattedDate(moment(sickLeave.sicknessEndDate))
                    }<br />
                  </Cell>
                  <Cell>
                    {sickLeave.days}
                  </Cell>
                  <Cell>
                    <SickLeaveFormStatusLabel
                      statusCode={sickLeave.status}
                      returnToWorkDate={sickLeave.returnToWorkDate}
                    />
                  </Cell>
                </Row>
              )
            })}
            </tbody>
          </Table>
        </TableContainer>
      )
    }
  }
}

export default AdminStaffSickLeavesTable;
